/*
 *  This file snackbar-alert.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar,MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';

@Injectable()
export class SnackbarAlertService {

  constructor(public snackBar: MatSnackBar, private zone: NgZone) { }

  Alert(message: string, validationType: string, action = 'OK',duration = 1000) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'bottom';
    if (validationType === '0') {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: message,
        panelClass: ['snackbar-success'],
        duration
      });
    } else if (validationType === '3') {
      config.panelClass = ['snackbar-validation'];
      this.zone.run(() => {
        this.snackBar.open(message, action, config);
      });
    } else if (validationType === '2') {
      config.panelClass = ['snackbar-error'];
      this.snackBar.open(message, action, config);
    } else {
      config.panelClass = ['snackbar-success'];
      this.snackBar.open(message, action, config);
    }
  }

}

/*
 *  This file global.selector.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2024
 *  (C) 2015-2024. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { createSelector } from '@ngrx/store';
import { TGlobalState } from './global.reducer';



export const selectGlobalState = (state: TGlobalState):TGlobalState => state;

export const selectFeedCards = createSelector(
  selectGlobalState,
  (state) => state.feed
);


/*
 *  This file shared.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { EventEmitter, Injectable } from '@angular/core';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { forkJoin, BehaviorSubject, Observable, of, Subject, EMPTY } from 'rxjs';
import { HttpService } from './http-service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import { SnackbarAlertService } from './snackbar-alert.service';
import { ConfirmationService } from 'primeng/api';
import { HRONE_CONSTANTS } from '../constants/all-hrone.constant';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { actionPageType } from 'src/app/modules/global-search/global-search.model';
import { RequestWorkflowAndStatusModel, LogonUserDetailModel, IdentifiersModel, monthNames, TokenInfoModel } from '../models/common.models';

import { has } from 'lodash';
import * as jwt from 'jwt-decode';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { ConfigurationService } from './configuration.service';
import { DatePipe, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { SharedStore, TSubscribeModuleResponse } from 'src/app/modules/team/team-dashboards/team-dashboards.model';
import { TGeneralEngageSettingResponse, THEME_LIST, TUserThemeInStorage } from 'src/app/modules/enterprise-wall/social-enterpise-wall/social-wall.model';
import * as  Zip from 'jszip'
import { cityUrls } from 'src/app/modules/core/organization/location/city/city.model';
import { IWorkforceImportListResponseItem } from 'src/app/main/sidebar/sidebar.model';
import { TDefaultDomainLogOnTypeDetailsResponse } from 'src/app/authentication/login/login.model';
import { TCoreOnboardCheckProcessResponse, TWorkforceSetupSettingResponse } from 'src/app/modules/onboarding-checklist/onboarding-checklist.model';
import { HroneGlobalStateService } from 'src/app/store/global/global-state.service';
import { catchError, map } from 'rxjs/operators';

const companyLogo = 'assets/images/newlogo.svg';
const companyBgImage = 'assets/images/Loginpreview.svg';
const companyHeading = '';
const companySubHeading = '';
const companyFontColor = "#000000";
const favicon = "assets/images/dynamic_images/favicon_hrone.ico";
const flashLoader = "/assets/images/init-loader-1.svg";
const title = "HROne";
const companyLogoGT = "assets/images/dynamic_images/demo.jpg";
const companyBgImageGT = "assets/images/Loginpreview.png";
const companyHeadingGT = "";
const companySubHeadingGT = "";
const companyFontColorGT = "#000000";
const faviconGT = "assets/images/dynamic_images/favicon.ico";
const flashLoaderGT = "assets/images/dynamic_images/loader-2.svg";
const titleGT = 'HRMS';


// HROne Enabling happier workplaces


@Injectable()
export class SharedService {
  sharedStore = new SharedStore<{ subcribedModule: TSubscribeModuleResponse }>();
  isGoogleDisabled: { disabled: boolean, called: boolean, loading: boolean } = { disabled: false, called: false, loading: false };
  isCtcPasswordProtected = true;
  informRoutingToGlobalSearch = new Subject<void>();
  ouApiCache = new Map<string, any>();
  templateId
  display = false;
  flag = false
  profile: string;
  phImages = environment.placeholders;
  smartSearchText = 'Search by name or code or designation or email id';
  isRedirectToNotification = false;
  // page call from team or global-search
  actionPageCallFrom: actionPageType = 'global';
  // end
  private dataSubject = new BehaviorSubject<boolean>(false);
  gotoDraftEvent = new EventEmitter()
  isSaveAsDraft = false
  data$ = this.dataSubject.asObservable();
  // Request id with label

  requestId = {
    attendance_regularization_request: 10,
    confirmed_investment_declaration: 37,
    leave_request: 9,
    loan_request: 23,
    on_duty_request: 11,
    proposed_investment_declaration: 25,
    reimbursement_request: 22,
    resignation: 3,
    restricted_holiday_request: 12,
    short_leave_request: 13,
    new_ticket_request: 52,
    expense_advance_request: 70,
    expense: 73,
    job_opening_request: 71,
    change_goal: 60,
    travel_request: 89,
    asset_request: 96,
    variable_request: 93,
    timesheet_request: 120,
    work_plan_request: 118
  }

  isShowAttachment = false;
  previousRouteForCandidateListPage = '';

  /* ----- validation msg from requirement team ------ */
  public validationMessages = {
    hours: 'Only one digit is allowed after decimal',
    required: 'This field should not be left blank',
    numericOnly: 'Please enter only numeric digits.',
    numericDecimal: 'Please enter decimal numeric digits.',
    alphabetOnly: 'Please enter alphabets only.',
    alphaNumericOnly: 'Please enter only alphanumeric characters',
    allowedCharacters: "a-z, A-Z, 0-9 and (_ @ # $ % & () *.', - ? / : ; ! |) characters are allowed",
    blankSpace: 'Blank spaces are not allowed.',
    spaceNotAllowed: 'Space is not allowed',
    zeroOnlyNotAllowed: 'Zero is not allowed',
    maxLength: 'The maximum character limit is',
    minLength: 'The minimum character limit is',
    maxNumberLength: 'The maximum value allowed is',
    minNumberLength: 'The minimum value allowed is',
    maxLength20: 'Maximum 20 characters are allowed',
    maxLength40: 'Maximum 40 characters are allowed',
    maxLength500: 'Maximum 500 characters are allowed',
    duplicate: 'This code series already exists. Please select a new one and try again.',
    invalidEmail: 'This is not a valid email. Please enter a valid one and try again.',
    invalidWebsite: 'This is not a valid website. Please enter a valid one and try again.',
    validDate: 'This is not a valid date. Please enter a valid date and try again.',
    invalidTime: 'This is not a valid time. Please enter a valid time and try again.',
    numericOrDecimal: 'Invalid input', //'This is not a valid number.Please enter a valid one and try again.', changed after disccus with meghna bug id ---- 30108
    parAccessDenied: "You do not have permission. Please contact your administrator.",
    parView: "You do not have permission to view. Please contact your administrator.",
    parAdd: "You do not have permission to add. Please contact your administrator.",
    parEdit: "You do not have permission to edit. Please contact your administrator.",
    parUpdate: "You do not have permission to update. Please contact your administrator.",
    parDelete: "You do not have permission to delete. Please contact your administrator.",
    parRemove: "You do not have permission to remove. Please contact your administrator.",
    parInactivate: "You do not have permission to inactivate. Please contact your administrator.",
    pan: 'This is not a valid PAN number.',
    passport: 'This is not a valid passport no. Please enter a valid one and try again.',
    aadhar: 'This is not a valid aadhar card number. Please enter a valid one and try again.',
    autoComplete: 'This is not a valid input. Please search a valid one.',
    lessFromDate: 'This date cannot be less than from date.',
    lessStartDate: 'This date cannot be less than start date.',
    valueGreaterThan: 'The value should be greater than',
    invalidRange: 'Duration of date search should be 90 days',
    bothDate: 'Please select both From and To date ',
    SSOIdRegx: 'a-z, A-Z, 0-9 and (_ @ . -) characters are allowed',
    paytmWallet: 'This is not a valid wallet id. Please enter a valid one and try again',
    ipAddressInvalid: 'please enter valid Ip address',
    isValidUrl: 'please enter valid url',
    numberRange: 'Please enter the digit between 1 to 6',
    allowedCharactersWithApostrophe: "a-z, A-Z, 0-9 and (_ @ # $ % & () *., - ? / : ; ! | ') characters are allowed",
    invalidLatitude: 'Invalid latitude',
    invalidLongitude: 'Invalid longitude',
    invalidFromToInput: 'Invalid range',
    mobile: 'This is not a valid number!',
    specialCharacterOnly: 'Only special characters are allowed',
    numericAndSpecialCharacter: 'Numeric and special characters are allowed',
    specialCharacterAndAlphabet: 'Special character and alphabet are allowed'
  };
  /* ----- */

  // custom badges list

  customBadges = [
    { path: 'assets/images/badges/custombadge/badge_1.png', color: '#ffc540', imageName: 'badge_1' },
    { path: '/assets/images/badges/custombadge/badge_2.png', color: '#7bff96', imageName: 'badge_2' },
    { path: '/assets/images/badges/custombadge/badge_3.png', color: '#615fff', imageName: 'badge_3' },
    { path: '/assets/images/badges/custombadge/badge_4.png', color: '#ce9fed', imageName: 'badge_4' },
    { path: '/assets/images/badges/custombadge/badge_5.png', color: '#548754', imageName: 'badge_5' },
    { path: '/assets/images/badges/custombadge/badge_6.png', color: '#2e4d34', imageName: 'badge_6' },
    { path: '/assets/images/badges/custombadge/badge_7.png', color: '#5fe5ff', imageName: 'badge_7' },
    { path: '/assets/images/badges/custombadge/badge_8.png', color: '#ed9fbd', imageName: 'badge_8' },
  ];

  // End custom badges
  public moduleId;

  tokenInfo: TokenInfoModel;
  logOnUserDetails: LogonUserDetailModel;
  logOnUserIdentifiers: IdentifiersModel = {
    organizationKeyOne: 0,
    organizationKeyTwo: 0
  };

  // tslint:disable-next-line: no-use-before-declare
  matcher = new MyErrorStateMatcher();

  rawMenus: any = [];
  rawMenusWithActions: any = [];
  whiteLabels = {};

  // HRONE CONSTANTS
  MENU_CODES = HRONE_CONSTANTS.MENU_CODES;
  imageSources = HRONE_CONSTANTS.imageSources;
  formIds = HRONE_CONSTANTS.formIds;
  moduleIds = HRONE_CONSTANTS.moduleIds;
  workFlowIds = HRONE_CONSTANTS.workFlowIds;
  ouIds = HRONE_CONSTANTS.ouIds;
  letterTemplateIds = HRONE_CONSTANTS.letterTemplateIds;
  // END HRONECONSTANTS

  timeZone = 'GMT+0530';

  confirmDialogData = {
    message: 'All data will be lost.',
    header: 'Do you want to close the window ?',
  };

  pendingStatusText = "Request is already pending";

  minValidDate = new Date(1753, 1, 1);

  companyDetails = {
    companyLogo: this._config.configData.isDeployOnGT ? companyLogoGT : companyLogo,
    companyBgImage: this._config.configData.isDeployOnGT ? companyBgImageGT : companyBgImage,
    companyHeading: this._config.configData.isDeployOnGT ? companyHeadingGT : companyHeading,
    companySubHeading: this._config.configData.isDeployOnGT ? companySubHeadingGT : companySubHeading,
    companyFontColor: this._config.configData.isDeployOnGT ? companyFontColorGT : companyFontColor,
    companyLink: null,
    favicon: this._config.configData.isDeployOnGT ? faviconGT : favicon,
    title: this._config.configData.isDeployOnGT ? titleGT : title,
    flashLoader: !this._config.configData.isDeployOnGT ? flashLoader : flashLoaderGT
  };
  displayHdFeedback = false;
  feedbackArr: any = [];
  dashboardRegimeRes = {
    financialYear: '',
    showTaxRegime: false,
    taxRegime: 'NEW',
    considerConfirmInvestment: false
  };
  isPulseRatingShow = false;
  commonLoginPopupData;

  pulseFeedbackArr = [];
  pulseFeedbackData;

  managerWidgetList = [];
  hrWidgetList = [];
  teamManagerWidgetList = [];
  monthNameArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  import_pageList: IWorkforceImportListResponseItem[] = [];

  notiCount: number;
  isShowPassword = false;

  isPRoductTourShown = false;
  supportUserInfo = null;
  isCompanyLogin = false;
  isPoweredByFlag = new BehaviorSubject(false);


  taskCounter = null;
  isVisibleUSerAnalytics = true;
  logOnTypeDetails!: TDefaultDomainLogOnTypeDetailsResponse;
  teamDirectList: any[] = [];
  taskData = [];
  betaValue: any;
  bulkArchiveSetting = {
    archiveDays: null,
    archivalType: '',
    isEnableBulkArchive: false
  };

  userLoggedOut: BehaviorSubject<boolean> = new BehaviorSubject(false);
  analyticsStorage: any = {};
  didUserLogout = false;

  isDirectoryFilterApplicable = false;
  isProfilePrivate = false;
  accessType = 'A'
  showNewOrgChart: boolean | null = null;
  isFromGoalFeedback = false;
  SubscribeModule: TSubscribeModuleResponse;
  bgClass: string;
  cityDropdown: Array<{ key: string, value: string, text: string }> = [];
  hrHanbookList = [];
  tokens = {
    teams: false,
    google: false
  }
  isGetImportPagesCalled: boolean;
  isGetImportPagesCalledInProgress: boolean;
  isOpenInNewTab = false;
  reportStatusMsg = "";
  isCurrentEmployeePriviledged: boolean;
  inboxHideHeaderTop: boolean = false;
  userLicenseInfo: IUserLicenseInfo;
  engageSetting: { engageSettingResponse: TGeneralEngageSettingResponse; loaded: boolean, loading: boolean } = { loaded: false, loading: false, engageSettingResponse: undefined }
  teamActionsList: any[] = [];
  viewAs = 'E';
  showAniversaryAnim = false;
  showBirthDayAnim = false;
  getDefaultDomainInformationBs = new BehaviorSubject<TDefaultDomainLogOnTypeDetailsResponse | null>(null);
  coreValueName: string = 'Core values';
  commmonSettingsList: ICommonsetting[] = [];
  newInsightCrossMarkObj = {
    FV: false,
    OT: false,
    PT: false,
    RE: false,
    AT: false
  };
  teamIndirectList: any[] = [];
  isReviewCalculationVisible = false;
  import_pageList_enterprise = [];
  hrDeskActionList = [];
  processes_searchList = [];
  reportList = [];
  websiteVersion = '';
  onboardingAccess = false;
  navigateToDocument: boolean = false;
  digiSignLicenseInfo = { active: false, apiCalled: false };
  isEmailVerified: string = 'N';
  pinnedMenus = [];

  constructor(
    public _httpService: HttpService,
    public router: Router,
    public _snackBar: SnackbarAlertService,
    public _confirmDialog: ConfirmationService,
    public _sanitizer: DomSanitizer,
    public _config: ConfigurationService,
    public datepipe: DatePipe,
    public _httpClient: HttpClient,
    public _location: Location,
    public _globalState: HroneGlobalStateService,
  ) {
    this._httpService.updateSignalRAuthTokenSubject.subscribe(() => {
      this.getTokenInfo();
    });
    this.setBgClass();
    localStorage.setItem('isNewInsight', `true`);
  }

  orgChartAccessRight(): void {
    this._globalState.getWorkforceSetupSetting(55).subscribe({
      next: (res) => {
        const showNew = res?.status === 'Y';
        this.showNewOrgChart = res?.status === 'D' ? null : showNew;
      },
      error: () => {
        this.showNewOrgChart = null;
      },
    });
  }

  reset() {
    this.import_pageList_enterprise = [];
    this.hrDeskActionList = [];
    this.processes_searchList = [];
    this.reportList = [];
    this.managerWidgetList = [];
    this.hrWidgetList = [];
    this.teamManagerWidgetList = [];
    this.analyticsStorage = {};
    this.didUserLogout = true;
    this.isGoogleDisabled = { disabled: false, called: false, loading: false };
    this.isGetImportPagesCalled = false;
    this.getDefaultDomainInformationBs.next(null);
    this.showAniversaryAnim = false;
    this.showBirthDayAnim = false;
    this.newInsightCrossMarkObj = {
      FV: false,
      OT: false,
      PT: false,
      RE: false,
      AT: false
    }
    localStorage.setItem('isNewInsight', `true`);
  }

  PoweredBy() {

    this._globalState.getWorkforceSetupSetting(27, null)
      .subscribe(res => {
        this.isPoweredByFlag.next(res?.isActive);
      })
  }

  getPoweredBy() {
    return this.isPoweredByFlag.asObservable();
  }

  public getCount(url, object, value, event?) {
    if (event) { event.stopPropagation(); }
    this._httpService.Get(url).subscribe(response => {
      object[value] = response;
    });
  }

  allowNumbersOnly(event) {
    const invalidChars = [
      '-', '+', 'e', '=', '~', ';', '/', ',', '?', '[', ']', '|', '\\', ':', '"', '<', '>', '*',
      '(', ')', '_', '{', '}', '!', '@', '#', '$', '%', '^', '&', '\'', 'E'
    ];
    if (invalidChars.includes(event.key) ||
      (event.target.value.length > 9 && event.key !== 'Backspace')) {
      event.preventDefault();
    }
  }

  allowIntegerOnly(event) {
    const invalidChars = [
      '-', '+', 'e', '=', '~', ';', '/', ',', '?', '[', ']', '|', '\\', ':', '"', '<', '>', '*',
      '(', ')', '_', '{', '}', '!', '@', '#', '$', '%', '^', '&', '\'', '.', 'E'
    ];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  }

  validateSpecialCharactersForFileName(name: string): boolean {
    const regex = /[/\\:"*?<>|]/;
    return regex.test(name);
  }

  minutesConvertor(minutes) {
    const min = + minutes;
    if (min > 0) {
      const hours = Math.floor(min / 60);
      const minutes = min % 60;
      return hours + 'H' + ":" + minutes + 'M';
    } else {
      return '00:00'
    }
  }

  hoursConvertor(hours) {
    return +hours * 60;
  }

  public checkShowMore(listLength, totalRecords) {
    if (listLength < totalRecords) {
      return true;
    } else {
      return false;
    }
  }

  public validateMinMax(min, max) {
    if (min === 0 || max === 0) {
      return true;
    }

    if (Number(min) > Number(max)) {
      return false;
    } else {
      return true;
    }

  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  compareMultiselectFn(a, b) {
    return a && b && a.id === b.id;
  }
  compareMultiselectFn2(a, b) {
    return a && b && a.Id === b.Id;
  }
  compareMultiselectFn3(a, b) {
    return a && b && a.Key === b.Key;
  }
  compareMultiselectFn4(a, b) {
    return a && b && a.key === b.key;
  }
  compareMultiselectFn5(a, b) {
    return a && b && a === b;
  }
  compareMultiselect(a, b) {
    return a && b && a.key === b.key;
  }
  compareMultiselectPlain(a, b) {
    return a && b && a === b;
  }

  removeMultiSelectFilter(selectFilter, appliedFilter, propKey, value) {
    selectFilter[propKey] = selectFilter[propKey].filter(data => data.key !== value);
    appliedFilter[propKey] = appliedFilter[propKey].filter(data => data.key !== value);
  }

  removeStatusFilter(selectFilter, appliedFilter, propKey) {
    selectFilter[propKey] = false;
    appliedFilter[propKey] = false;
  }

  removeAllFilter(selectFilter, appliedFilter, skeleton) {
    const sf = Object.keys(selectFilter);
    const af = Object.keys(appliedFilter);
    Object.keys(skeleton).forEach(key => {
      const sfi = sf.indexOf(key);
      const afi = af.indexOf(key);
      if (sfi >= 0) {
        selectFilter[key] = skeleton[key];
      }
      if (afi >= 0) {
        appliedFilter[key] = skeleton[key];
      }
    });
  }

  removeCheckboxListFilter(checkboxList, appliedFilter, key, value) {
    appliedFilter[key] = appliedFilter[key].filter(data => data.value !== value);
    const fi = checkboxList.findIndex(data => data.value === value);
    if (fi >= 0) {
      checkboxList[fi].status = false;
    }
  }

  getUserDetails() {
    this._httpService.Get('/api/LogOnUser/LogOnUserDetail').subscribe(response => {

      localStorage.setItem('logOnUserDetails', JSON.stringify(response));
      this.logOnUserDetails = response;
      if (this.logOnUserDetails && this.logOnUserDetails.identifier) {
        this.logOnUserIdentifiers = this.logOnUserDetails.identifier;
      }
    });
  }

  convertFormGroupNullValueToEmptyString(formGroup: any) {
    Object.keys(formGroup.controls).forEach(key => {
      if (formGroup.get(key).value === null) {
        formGroup.get(key).setValue('');
      }
    });
  }

  convertNullToEmptyString(data) {
    console.log('before null to empty', data);
    Object.keys(data).forEach(key1 => {

      if (data[key1] === null) {
        data[key1] = '';
      } else if (Array.isArray(data[key1])) {
        for (let i = 0; i < data[key1].length; i++) {
          Object.keys(data[key1][i]).forEach(key2 => {
            if (data[key1][i][key2] === null) {
              data[key1][i][key2] = '';
            }
          });
        }
      } else if (typeof data[key1] === 'object' && data[key1] !== null) {
        Object.keys(data[key1]).forEach(key2 => {
          if (data[key1][key2] === null) {
            data[key1][key2] = '';
          }
        });
      }

    });
    console.log('after null to empty', data);
  }

  getIdentifier(type: 1 | 2, empInfo) {
    let key = '';
    let orgKey;

    if (type === 1) {
      orgKey = this.logOnUserIdentifiers.organizationKeyOne;
    } else if (type === 2) {
      orgKey = this.logOnUserIdentifiers.organizationKeyTwo;
    }

    if (orgKey && empInfo) {

      if (orgKey === 1) {
        key = empInfo['company'];
      } else if (orgKey === 14) {
        key = empInfo['businessUnit'];
      } else if (orgKey === 4) {
        key = empInfo['department'];
      } else if (orgKey === 7) {
        key = empInfo['subDepartment'];
      } else if (orgKey === 2) {
        key = empInfo['designation'];
      } else if (orgKey === 3) {
        key = empInfo['grade'];
      } else if (orgKey === 8) {
        key = empInfo['level'];
      } else if (orgKey === 9) {
        key = empInfo['region'];
      } else if (orgKey === 10) {
        key = empInfo['branch'];
      } else if (orgKey === 11) {
        key = empInfo['subBranch'];
      }
    }

    return key;
  }

  getIdentifierName(type: 1 | 2, empInfo) {
    let key = '';
    let orgKey;

    if (type === 1) {
      orgKey = this.logOnUserIdentifiers.organizationKeyOne;
    } else if (type === 2) {
      orgKey = this.logOnUserIdentifiers.organizationKeyTwo;
    }

    if (orgKey && empInfo) {

      if (orgKey === 1) {
        key = empInfo['companyName'];
      } else if (orgKey === 14) {
        key = empInfo['businessUnitName'];
      } else if (orgKey === 4) {
        key = empInfo['departmentName'];
      } else if (orgKey === 7) {
        key = empInfo['subDepartmentName'];
      } else if (orgKey === 2) {
        key = empInfo['designationName'];
      } else if (orgKey === 3) {
        key = empInfo['gradeName'];
      } else if (orgKey === 8) {
        key = empInfo['levelName'];
      } else if (orgKey === 9) {
        key = empInfo['regionName'];
      } else if (orgKey === 10) {
        key = empInfo['branchName'];
      } else if (orgKey === 11) {
        key = empInfo['subBranchName'];
      }
    }

    return key;
  }

  BASIC_CONST = {
    employeeCode: {
      prop: 'employeeCode'
    },
    salutationName: {
      prop: 'salutation'
    },
    firstName: {
      prop: 'firstName'
    },
    middleName: {
      prop: 'middleName'
    },
    lastName: {
      prop: 'lastName'
    },
    dateOfBirth: {
      prop: 'dateOfBirth',
      date: true
    },
    genderName: {
      prop: 'genderId'
    },
    bloodGroupName: {
      prop: 'bloodGroupCode'
    },
    nationalityName: {
      prop: 'nationality'
    },
    mobileNo: {
      prop: 'mobileNo'
    },
    officialEmail: {
      prop: 'officialEmail'
    },
    dateOfJoining: {
      prop: 'dateOfJoining',
      date: true
    },
    employeeType: {
      prop: 'employeeTypeId'
    },

    employeeStatus: {
      prop: 'employeeStatusId'
    },
    dateOfConfirmation: {
      prop: 'dateOfConfirmation',
      date: true,
    },
    dateOfResignation: {
      prop: 'dateOfResignation',
      date: true,
    },
    dateOfLeaving: {
      prop: 'dateOfLeaving',
      date: true,
    },
    dateOfSettlement: {
      prop: 'dateOfSettlement',
      date: true,
    },

    otherStatusName: {
      prop: 'otherStatusId'
    },
    otherStatusDate: {
      prop: 'otherStatusDate',
      date: true
    },
    otherStatusRemarks: {
      prop: 'otherStatusRemarks'
    },

    businessUnit: {
      prop: 'businessUnitCode'
    },
    companyName: {
      prop: 'companyCode'
    },
    departmentName: {
      prop: 'departmentCode'
    },
    subDepartmentName: {
      prop: 'subDepartmentCode'
    },
    designationName: {
      prop: 'designationCode'
    },
    branchName: {
      prop: 'branchCode'
    },
    subBranchName: {
      prop: 'subBranchCode'
    },
    gradeName: {
      prop: 'gradeCode'
    },
    levelName: {
      prop: 'levelCode'
    },
    reportingManager: {
      prop: 'reportingManagerId',
      add: 'reportingManagerCode'
    },
    functionalManager: {
      prop: 'functionalManagerId',
      add: 'functionalManagerCode'
    },
    skillType: {
      prop: 'skillTypeId'
    },
    regionName: {
      prop: 'regionCode'
    },
    biometricId: {
      prop: 'biometricId'
    },
    internationalCode: {
      prop: 'internationalCode'
    }

  };

  getIdentifierOne(): TIdentifierKey {
    let key: TIdentifierKey = 'designation';
    if (this.logOnUserIdentifiers && this.logOnUserIdentifiers.organizationKeyOne) {
      if (this.logOnUserIdentifiers.organizationKeyOne === 1) {
        key = 'company';
      } else if (this.logOnUserIdentifiers.organizationKeyOne === 14) {
        key = 'businessUnit';
      } else if (this.logOnUserIdentifiers.organizationKeyOne === 4) {
        key = 'department';
      } else if (this.logOnUserIdentifiers.organizationKeyOne === 7) {
        key = 'subDepartment';
      } else if (this.logOnUserIdentifiers.organizationKeyOne === 2) {
        key = 'designation';
      } else if (this.logOnUserIdentifiers.organizationKeyOne === 3) {
        key = 'grade';
      } else if (this.logOnUserIdentifiers.organizationKeyOne === 8) {
        key = 'level';
      } else if (this.logOnUserIdentifiers.organizationKeyOne === 9) {
        key = 'region';
      } else if (this.logOnUserIdentifiers.organizationKeyOne === 10) {
        key = 'branch';
      } else if (this.logOnUserIdentifiers.organizationKeyOne === 11) {
        key = 'subBranch';
      }
    }

    return key;
  }

  getIdentifierTwo(): TIdentifierKey {
    let key: TIdentifierKey = 'grade';
    if (this.logOnUserIdentifiers && this.logOnUserIdentifiers.organizationKeyTwo) {
      if (this.logOnUserIdentifiers.organizationKeyTwo === 1) {
        key = 'company';
      } else if (this.logOnUserIdentifiers.organizationKeyTwo === 14) {
        key = 'businessUnit';
      } else if (this.logOnUserIdentifiers.organizationKeyTwo === 4) {
        key = 'department';
      } else if (this.logOnUserIdentifiers.organizationKeyTwo === 7) {
        key = 'subDepartment';
      } else if (this.logOnUserIdentifiers.organizationKeyTwo === 2) {
        key = 'designation';
      } else if (this.logOnUserIdentifiers.organizationKeyTwo === 3) {
        key = 'grade';
      } else if (this.logOnUserIdentifiers.organizationKeyTwo === 8) {
        key = 'level';
      } else if (this.logOnUserIdentifiers.organizationKeyTwo === 9) {
        key = 'region';
      } else if (this.logOnUserIdentifiers.organizationKeyTwo === 10) {
        key = 'branch';
      } else if (this.logOnUserIdentifiers.organizationKeyTwo === 11) {
        key = 'subBranch';
      }
    }

    return key;
  }

  getIdentifierLabel(type: number) {
    let key = 'Designation';
    if (this.logOnUserIdentifiers && this.logOnUserIdentifiers.organizationKeyOne && this.logOnUserIdentifiers.organizationKeyTwo) {
      let orgKey = this.logOnUserIdentifiers.organizationKeyOne;
      if (type === 2) {
        orgKey = this.logOnUserIdentifiers.organizationKeyTwo;
      }

      if (orgKey === 1) {
        key = 'Company';
      } else if (orgKey === 14) {
        key = 'Business unit';
      } else if (orgKey === 4) {
        key = 'Department';
      } else if (orgKey === 7) {
        key = 'Sub department';
      } else if (orgKey === 2) {
        key = 'Designation';
      } else if (orgKey === 3) {
        key = 'Grade';
      } else if (orgKey === 8) {
        key = 'Level';
      } else if (orgKey === 9) {
        key = 'Region';
      } else if (orgKey === 10) {
        key = 'Branch';
      } else if (orgKey === 11) {
        key = 'Sub branch';
      }
    }

    return key;
  }

  public getModuleId() {
    this.router.url.split('/').forEach(menuname => {
      switch (menuname) {
        case 'workforce': this.moduleId = 1;
          break;
        case 'timeoffice': this.moduleId = 2;
          break;
        case 'payroll': this.moduleId = 3;
          break;
        case 'performance': this.moduleId = 4;
          break;
        case 'expense': this.moduleId = 5;
          break;
        case 'recruitment': this.moduleId = 9;
          break;
        case 'asset': this.moduleId = 7;
          break;
        case 'work-from-home': this.moduleId = 23;
          break;
      }
    });
    return this.moduleId;
  }

  getGlobalSearcheEmployees() {
    const searchedEmployees = this.isOpenInNewTab ? JSON.parse(sessionStorage.getItem('globalSearchedEmployeesInNewTab')) : JSON.parse(localStorage.getItem('globalSearchedEmployees'));
    let searchedEmployeesString = '';
    if (searchedEmployees) {
      searchedEmployeesString = searchedEmployees.map(e => e.id).join(',');
    }
    return searchedEmployeesString ? searchedEmployeesString : '';
  }

  getOnbehalfEmployee() {
    const empId = localStorage.getItem('onBehalfEmployee');
    return Number(empId);
  }

  pScrollToTop(ps: PerfectScrollbarComponent) {
    if (ps.directiveRef) {
      ps.directiveRef.scrollToTop(0, 0);
    }
  }

  pScrollToBottom(ps: PerfectScrollbarComponent) {
    if (ps.directiveRef) {
      ps.directiveRef.scrollToBottom(0, 0);
    }
  }

  pScrollToXY(ps: PerfectScrollbarComponent, y: number) {
    if (ps.directiveRef) {
      ps.directiveRef.scrollTo(0, y);
    }
  }

  goToActionHome() {
    if (this.actionPageCallFrom === 'global') {
      this.goToGSearchHome();
    } else if (this.actionPageCallFrom === 'team') {
      this.goToTeamHome();
    }
  }

  goToGSearchHome() {
    setTimeout(() => { this.router.navigate(['/app/processes/search']); }, 100);

  }

  goToTeamHome() {
    setTimeout(() => { this.router.navigate(['/app/team/myteam']); }, 100);
  }

  redirectIfNotRights() {
    setTimeout(() => {
      this.router.navigate(['/app']);
    }, 110);

  }



  dateChange(control) {
    if (!control.value) {
      control.setValue('');
    }
    control.updateValueAndValidity();
  }

  downloadUnploadedFile(path: any) {
    if (path &&
      (path.indexOf('https://') !== -1
        || path.indexOf('http://') !== -1)) {
      window.location.href = path;

    }
  }

  getRequestStatus(empId, flowId, payCode?): Promise<any> {
    return new Promise((resolve, reject) => {
      const subtype = payCode ? payCode : '0';
      const url = '/api/core/Workflow/Request/Status/' + empId + '/' + flowId + '/' + subtype;
      this._httpService.Get(url).subscribe(resposne => {
        resolve(resposne);
      }, () => {
        reject('Error');
      });
    });
  }

  getRequestWorkflowAndStatus(empId, flowId, subType?): Promise<RequestWorkflowAndStatusModel> {

    return new Promise((resolve) => {

      const sub_type = subType ? subType : '0';
      const statusUrl = '/api/core/Workflow/Request/Status/' + empId + '/' + flowId + '/' + sub_type;
      const workFlowUrl = '/api/core/ValidateWorkflow/' + empId + '/' + flowId;
      const statusRequest = this._httpService.Get(statusUrl);
      const workFlowRequest = this._httpService.Get(workFlowUrl);

      const requestStatus: RequestWorkflowAndStatusModel = {
        isInvalid: true,
        reason: ''
      };

      forkJoin([workFlowRequest, statusRequest]).toPromise()
        .then(response => {
          if (response[0].validationType === '0') {
            if (response[1] === 0) {
              requestStatus.isInvalid = true;
              requestStatus.reason = 'Your last request is pending, you cannot initiate new request';
            } else {
              requestStatus.isInvalid = false;
            }
          } else {
            requestStatus.isInvalid = true;
            requestStatus.reason = response[0].message;
          }
          resolve(requestStatus);
        }).catch(() => {
          resolve(requestStatus);
        });

    });
  }

  removeNewLine(str: string) {
    const rstr =
      // eslint-disable-next-line no-control-regex
      str.replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g, " ");
    return rstr ? rstr : '';
  }

  preventEnterKey(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  compareFormData(newData, preData, isRecursive?) {
    let isChanged = false;

    if (!preData) {
      return true;
    }

    try {
      const arr = Object.keys(newData);
      for (let i = 0; i < arr.length; i++) {
        const objProp = arr[i];
        if (has(preData, objProp)) {
          if (Array.isArray(newData[objProp]) && Array.isArray(preData[objProp])) {
            if (newData[objProp].length === preData[objProp].length) {
              for (let j = 0; j < newData[objProp].length; j++) {
                if (!isChanged) {
                  isChanged = this.compareFormData(newData[objProp][j], preData[objProp][j], true);
                } else {
                  isChanged = true;
                }
                if (isChanged) {
                  break;
                }
              }
            } else {
              isChanged = true;
            }
          } else if ((typeof objProp === "object") && objProp !== null && !isChanged) {
            const newDataItem = objProp ? newData[objProp] : undefined;
            const preDataItem = objProp ? preData[objProp] : undefined;
            isChanged = this.compareFormData(newDataItem, preDataItem, true);
          } else {
            if (typeof newData[objProp] === 'string') {
              newData[objProp] = newData[objProp].trim();
            }
            if (typeof preData[objProp] === 'string') {
              preData[objProp] = preData[objProp].trim();
            }
            if ((newData[objProp] || preData[objProp]) && (newData[objProp] != preData[objProp])) {
              isChanged = true;
            }
          }
        }
        if (isChanged) {
          break;
        }
      }

      if (!isRecursive) {
        if (!isChanged) {
          this._snackBar.Alert('Please fill some data', '3');
        }
      }
      return isChanged;

    } catch (err) { }

  }

  getAppVersion() {
    this._httpService.GetWithoutHeader('/api/Default/ApplicationVersion').subscribe((response: any) => {
      if (response && response.length) {
        this.websiteVersion = response[0].websiteVersion;
        const localV = localStorage.getItem('versionId');
        localStorage.setItem('versionId', response[0].versionId);
        if (localV && localV != response[0].versionId) {
          location.reload();
        }
      }
    });
  }

  getComapanyDetails(domainCode) {
    this.companyDetails = {
      companyLogo: null,
      companyBgImage: null,
      companyHeading: null,
      companySubHeading: null,
      companyFontColor: null,
      companyLink: null,
      favicon: favicon,
      title: title,
      flashLoader: flashLoader
    };
    this.getDefaultDomainInformationOb(domainCode).subscribe((response: any) => {
      this.companyDetails.companyLogo = response && response.fileVirtualPath ? response.fileVirtualPath : this.isDeployedOnGT ? companyLogoGT : companyLogo;
      this.companyDetails.companyHeading = response && response.enterpriseBackgroundEntity ? response.enterpriseBackgroundEntity.backgroundHeading : this.isDeployedOnGT ? companyHeadingGT : companyHeading;
      this.companyDetails.companySubHeading = response && response.enterpriseBackgroundEntity ? response.enterpriseBackgroundEntity.backgroundSubheading : this.isDeployedOnGT ? companySubHeadingGT : companySubHeading;
      this.companyDetails.companyFontColor = response && response.enterpriseBackgroundEntity ? response.enterpriseBackgroundEntity.backgroundFontColor : companyFontColor;
      this.companyDetails.companyLink = response && response.enterpriseBackgroundEntity && response.enterpriseBackgroundEntity.enterprisePath ? response.enterpriseBackgroundEntity.enterprisePath : null;
      this.companyDetails.companyBgImage = response && response.enterpriseBackgroundEntity && response.enterpriseBackgroundEntity.backgroundImageVirtualPath ? response.enterpriseBackgroundEntity.backgroundImageVirtualPath : this.isDeployedOnGT ? companyBgImageGT : companyBgImage;
    }, () => {
      this.getDefaultCompDetails();
    });


  }

  getDefaultDomainInformationOb(domainCode: string) {
    const cache = this.getDefaultDomainInformationBs.getValue();
    if (!cache) {
      this._httpService.GetWithoutHeader('/api/Default/' + domainCode).subscribe({
        next: (response: TDefaultDomainLogOnTypeDetailsResponse) => {
          this.getDefaultDomainInformationBs.next(response);
        }
      });
    }
    else {
      this.getDefaultDomainInformationBs.next(cache);
    }
    return this.getDefaultDomainInformationBs.asObservable();
  }



  getDefaultCompDetails() {
    this.companyDetails = {
      companyLogo: this._config.configData.isDeployOnGT ? companyLogoGT : companyLogo,
      companyBgImage: this._config.configData.isDeployOnGT ? companyBgImageGT : companyBgImage,
      companyHeading: this._config.configData.isDeployOnGT ? companyHeadingGT : companyHeading,
      companySubHeading: this._config.configData.isDeployOnGT ? companySubHeadingGT : companySubHeading,
      companyFontColor: this._config.configData.isDeployOnGT ? companyFontColorGT : companyFontColor,
      companyLink: null,
      favicon: this._config.configData.isDeployOnGT ? faviconGT : favicon,
      title: this._config.configData.isDeployOnGT ? titleGT : title,
      flashLoader: this._config.configData.isDeployOnGT ? flashLoaderGT : flashLoader
    };
  }

  getMonthName(monthNo): string {
    if (monthNo) {
      return monthNames.short[monthNo];
    } else {
      return '';
    }
  }

  goToPage(page: 'inbox' | 'request' | 'profile') {

    if (page === 'inbox') {
      this.router.navigate(['app/inbox']);
    } else if (page === 'request') {
      this.router.navigate(['app/request/initiatedrequest']);
    } else if (page === 'profile') {
      this.router.navigate(['/app/myprofile/profile']);
    }

  }

  getTokenInfo() {
    let tokenInfo: any;
    const auth = JSON.parse(localStorage.getItem('authlogin'));
    const token = auth?.access_token ? auth.access_token : '';
    try {
      tokenInfo = jwt(token);
    } catch (error) {
      tokenInfo = null;
    }

    this.tokenInfo = tokenInfo;

  }

  isFeatureAvailable(featureId): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._globalState.validateFeature(featureId)
        .subscribe(response => {
          if (response && response.isActive) {
            resolve(true);
          } else {
            resolve(false);
          }
        },
          () => {
            reject(false);
          });
    });
  }


  getPulseData(data) {
    this._httpService.Get('/api/engage/employee/Weightage/Rating/Details/' + data.requestId).subscribe(response => {
      if (response) {
        this.pulseFeedbackData = response;
        this.isPulseRatingShow = true;
      }
    });
  }

  stopPropagation(event) {
    try { event.stopPropagation(); } catch (err) { }
  }

  checkDate(fromDate: Date, toDate: Date) {
    const fD = new Date(fromDate);
    const tD = new Date(toDate);
    const a = moment([fD.getFullYear(), (fD.getMonth()), fD.getDate()]);
    const b = moment([tD.getFullYear(), (tD.getMonth()), tD.getDate()]);
    return (90 >= (b.diff(a, 'days'))) ? true : false;
  }

  checkMenuAccess(menuId) {
    return new Promise((resolve, reject) => {
      const url = '/api/core/MenuAccess';
      const payload = {
        "menuType": "",
        "parentKey": 9999,
        "menuId": menuId,
        "includeMenu": false
      };

      this._httpService.Post(url, payload).toPromise().then(res => {
        const list = res ? res : [];
        if (list.findIndex(ele => ele.menuId == menuId) >= 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      }).catch(() => {
        reject(false);
      })
    });

  }

  getImportPages(): Observable<IWorkforceImportListResponseItem[]> {
    const bs = new BehaviorSubject(this.import_pageList);
    if (this.isGetImportPagesCalled || this.isGetImportPagesCalledInProgress) return bs.asObservable();
    this.isGetImportPagesCalledInProgress = true;
    this.import_pageList = [];
    const args = {
      'moduleId': 0,
      'importName': '',
      'forPin': 0
    };
    this._httpService.Post('/api/workforce/import/ImportList', args)
      .subscribe({
        next: response => {
          if (response) {
            this.import_pageList = response;
            bs.next(this.import_pageList);
            this.isGetImportPagesCalled = true;
            this.isGetImportPagesCalledInProgress = true;
          }
        },
        error: () => {
          this.isGetImportPagesCalledInProgress = true;
        }
      });
    return bs.asObservable();
  }
  getGoogleMeetFeatured(): any {
    return new Promise((resolve, reject) => {
      this._httpService.Get(`/api/core/feature/Validate/${207}`).subscribe(res => {
        if (res) {
          resolve(res.isActive);
        } else {
          resolve(0);
        }
      }, err => reject(err));
    });
  }


  getNotiCount() {
    this._httpService.Get('/api/core/Dashboard/Notification/count').subscribe(res => {
      this.notiCount = Number(res);
    });
  }

  public namingConvention(value) {
    const lowerC = value.toLowerCase();
    const changedData = lowerC.charAt(0).toUpperCase() + lowerC.substring(1);
    return changedData;
  }

  timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    let days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    let hours: any = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    let minutes: any = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let seconds: any = Math.floor(diffInMilliSeconds) % 60;

    if (days > 0) {
      days = days * 24;
      hours = days + hours;
    }
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    const difference = [minutes, seconds].join(' : ');
    return difference;
  }
  isScreenLockInIdle() {
    return new Promise((resolve, reject) => {
      this._globalState.getWorkforceSetupSetting(22).subscribe(res => {
        if (res && res.status === 'Y') {
          resolve(true);
        } else {
          resolve(false);
        }
      }, err => {
        reject(err);
      })
    });
  }

  getEmployeeLateCount(calenderData) {
    return calenderData && calenderData.filter(e => e.presentStatus == 'Late' || e.presentStatus == 'Early').length;
  }

  isGlobalSettingEnable(id) {
    return new Promise((resolve, reject) => {
      this._globalState.getWorkforceSetupSetting(id, null).subscribe(res => {
        if (res && res.status === 'Y') {
          resolve(true);
        } else {
          resolve(false);
        }
      }, err => {
        reject(err);
      })
    });
  }

  getInboxCounter(moduleId) {
    this.isVisibleUSerAnalytics = true;

    this._httpService.Get('/api/inbox/taskCount/' + moduleId).subscribe(res => {
      this.taskCounter = res ? res : null;

    }
    )
  }

  convertDateToTime(date: any): string {
    let time = date;
    if (date instanceof Date) {
      const hour = date.getHours() < 10 ? ('0' + date.getHours()) : date.getHours();
      const minutes = date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes();
      time = hour + ':' + minutes;
    }
    return time;
  }

  convertTimeToDate(time: any): Date {
    const date = new Date();
    if (typeof time == 'string') {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate(), Number(time.substring(0, 2)), Number(time.substring(3)));
    }
    return time;
  }

  getCodeSeries(id) {
    return new Promise((resolve, reject) => {
      const url = `/api/core/CodeSeriesApplicability/${id}`;
      this._httpService.Get(url)
        .subscribe(
          (response) => {
            resolve(response);
          },
          () => {
            reject(null);
          });

    });
  }

  getVerificationStatus(key: string, value: any, bgvVerificationDetails: any[]): string {
    if (!bgvVerificationDetails.length) {
      return '2';
    }
    const verDetail = bgvVerificationDetails.filter(det => det.originalFieldName === key);
    if (key == 'DOB' && value) {
      value = this.datepipe.transform(value, 'yyyy-MM-dd');
    }
    if (verDetail.length) {
      const verifiedFromAadhar = verDetail.find(det => (det.aadhaarKycFieldValue || '').toString().trim().toLowerCase() == (value || '').toString().trim().toLowerCase());
      const verifiedFromPan = verDetail.find(det => (det.panKycFieldValue || '').toString().trim().toLowerCase() == (value || '').toString().trim().toLowerCase());
      if (verifiedFromAadhar || verifiedFromPan) {
        return '0';
      } else if (!verDetail[0].aadhaarKycFieldValue && !verDetail[0].panKycFieldValue) {
        return '2';
      } else {
        return '1';
      }
    } else {
      return '2';
    }
  }

  getVerificationTooltip(key: string, value: any, bgvVerificationDetails: any[]): string {
    if (!value) {
      return '';
    }
    if (!bgvVerificationDetails.length) {
      return 'Not available';
    }
    const verDetail = bgvVerificationDetails.filter(det => det.originalFieldName === key);
    if (key == 'DOB' && value) {
      value = this.datepipe.transform(value, 'yyyy-MM-dd');
    }
    if (verDetail.length) {
      const verifiedFromAadhar = verDetail.find(det => (det.aadhaarKycFieldValue || '').toString().trim().toLowerCase() == (value || '').toString().trim().toLowerCase());
      const verifiedFromPan = verDetail.find(det => (det.panKycFieldValue || '').toString().trim().toLowerCase() == (value || '').toString().trim().toLowerCase());
      if (verifiedFromAadhar || verifiedFromPan) {
        if (value && verifiedFromAadhar && verifiedFromPan) {
          return 'Fetched from Aadhar,PAN';
        }
        else if (value && verifiedFromAadhar && !verifiedFromPan) {
          return 'Fetched from Aadhar';
        }
        else if (value && !verifiedFromAadhar && verifiedFromPan) {
          return 'Fetched from PAN';
        }
      } else if (!verDetail[0].aadhaarKycFieldValue && !verDetail[0].panKycFieldValue) {
        return 'Not available';
      }
      else {
        return 'Not verified: ' + (verDetail[0].aadharValue ? verDetail[0].aadharValue : verDetail[0].panValue);
      }
    } else {
      return 'Not available';
    }
  }

  getTeamDirectList(): Promise<any> {
    return new Promise((resolve, reject) => {
      const req_data = { reportId: 1, reportingTeam: 'RM', teamSearch: '', pagination: { pageNumber: 1, pageSize: 99999 } };
      if (this.teamDirectList.length) {
        resolve(this.teamDirectList);
      } else {
        this._httpService.Post('/api/workforce/Team/Direct/Indirect', req_data)
          .subscribe(
            (response) => {
              this.teamDirectList = response ? response : [];
              resolve(this.teamDirectList);
            },
            () => {
              reject([]);
            });
      }
    });
  }

  getTaskData(refresh = false): void {
    if (!this.taskData.length || refresh) {
      this._httpService.Get('/api/core/Workflow/WorkflowInboxSettingList').subscribe(response => {
        this.taskData = response ? response : []
      });
    }
  }


  // code for read new-beta.json
  readNewBetaJson(): any {
    return new Promise(() => {
      this._httpClient.get('/assets/json/new-beta.json')
        .toPromise()
        .then((data: any) => {
          this.betaValue = data;
        });
    });
  }
  bindNewBetaOnMenu(res) {
    if (this.betaValue) {
      res.forEach((obj) => {
        obj.isNewBeta = this.betaValue?.beta[0]?.arrMenuId?.includes(obj.menuId) ? 'BETA' :
          this.betaValue?.new[0]?.arrMenuId?.includes(obj.menuId) ? 'NEW' : '';
      });
    }
  }
  bindNewBetaOnModule(response) {
    if (this.betaValue) {
      response.forEach((obj) => {
        obj.isNewBeta = this.betaValue?.beta[0]?.arrModuleId?.includes(obj.moduleId) ? 'BETA' :
          this.betaValue?.new[0]?.arrModuleId?.includes(obj.moduleId) ? 'NEW' : '';
      });
    }
  }
  bindNewBetaOnAccessRights(res) {
    if (this.betaValue) {
      res.forEach((obj) => {
        obj.isNewBeta = this.betaValue?.beta[0]?.accessRightId?.includes(obj.accessRightId) ? 'BETA' :
          this.betaValue?.new[0]?.accessRightId?.includes(obj.accessRightId) ? 'NEW' : '';
      });
    }
  }

  getApplicatbility(orgIds = '1,2,3,4,7,8,9,10,11,14') {
    return new Promise((resolve, reject) => {
      this._httpService.Get(`/api/core/CodeSeries/OrganizationPriority/${orgIds}`).subscribe(response => {
        const data = response ? response : [];
        resolve(data)
      }, () => reject([]));
    });
  }

  getbulkarchiveSetting() {
    const URL = `/api/core/autoarchive`;
    this._httpService.Get(URL).subscribe(res => {
      if (res) {
        this.bulkArchiveSetting = res;
      }
    });
  }

  getHelpDeskSettings(): Promise<boolean> {
    return new Promise((resolve) => {
      this._httpService.Get('/api/helpdesk/setting/policy/Helpdesk/Setting').subscribe(res => {
        if (res && res.showSLAStatus) {
          resolve(true);
        } else {
          resolve(false)
        }
      });
    })
  }

  setDirectoryFilter(isDir: boolean) {
    this.isDirectoryFilterApplicable = isDir;
  }

  get getDirectoryFilter() {
    return this.isDirectoryFilterApplicable ? 1 : 0;
  }

  setIsProfilePrivate(isDir: boolean) {
    this.isProfilePrivate = isDir;
  }

  get getIsProfilePrivate() {
    return this.isProfilePrivate;
  }

  getEngageSetting(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.engageSetting.engageSettingResponse) {
        resolve();
        return;
      }
      this.engageSetting.loaded = true;
      this.engageSetting.loading = true;
      this._httpService.Get("/api/Engage/Setting/GeneralSetting").subscribe(
        {
          next: (res: TGeneralEngageSettingResponse) => {
            this.engageSetting.engageSettingResponse = res;
            if (res) {
              this.isShowAttachment = res.isShowAttachment;
              this.setDirectoryFilter(res.wallApplicability);
            }
            this.engageSetting.loading = false;
            resolve();
          },
          error: () => {
            this.engageSetting.loading = false;
            resolve();
          }
        }
      );
    });
  }

  get isDeployedOnGT(): boolean {
    return Boolean(this._config.configData && this._config.configData.isDeployOnGT);
  }

  refreshRecentSearchedProfileData(): void {
    const recentsearchList = JSON.parse(localStorage.getItem('recent_profile_searches'));
    if (recentsearchList && recentsearchList.length) {
      recentsearchList.forEach((search, index) => {
        const ind = (this.rawMenus || []).findIndex(menu => menu.menuId == search.menuId);
        if (ind == -1) {
          recentsearchList.splice(index, 1);
        }
      });
      localStorage.setItem('recent_profile_searches', JSON.stringify(recentsearchList || []));
    }
  }
  setEmployeeNameFromCode(list): { key: number, value: string }[] {
    return list.map(employee => ({ key: employee.employeeIdA, value: `${employee.employeeName} #${employee.employeeCode}` }))
  }
  getUserThemeFromStorage(): TUserThemeInStorage {
    localStorage.removeItem('selectedThemeColorIndex');
    const theme: string = localStorage.getItem('selectedTheme');
    let userThemeFromStorage: TUserThemeInStorage = theme ? JSON.parse(theme) : { default: 0, user: null, hr: null };
    let count = 0;
    while (typeof userThemeFromStorage === 'string' && count < 10) {
      userThemeFromStorage = JSON.parse(userThemeFromStorage);
      count++;
    }
    return userThemeFromStorage;
  }
  getCurrentThemeId(): number {
    const userThemeFromStorage: TUserThemeInStorage = this.getUserThemeFromStorage();
    let colorIndex = 0;
    if (userThemeFromStorage.user) {
      colorIndex = userThemeFromStorage.user;
    }
    else if (userThemeFromStorage.hr) {
      colorIndex = userThemeFromStorage.hr;
    }
    return colorIndex;
  }
  setBgClass(): void {
    let colorIndex = this.getCurrentThemeId();
    colorIndex = colorIndex > THEME_LIST.length ? 0 : colorIndex;
    const userThemeFromStorage: TUserThemeInStorage = this.getUserThemeFromStorage();
    this.setThemeToStorage(userThemeFromStorage);
    const newbgClass = THEME_LIST[colorIndex];
    const finalclass = Object.keys(newbgClass)[0];
    if (document.body.classList.contains('myycolor')) {
      document.body.removeAttribute('class');
    }
    document.body.setAttribute('class', finalclass)

  }
  setThemeToStorage(userThemeFromStorage: TUserThemeInStorage) {
    localStorage.setItem('selectedTheme', JSON.stringify(userThemeFromStorage));
  }

  async generateZip(promisList: Array<Promise<TDownloadPromise>>, zipName: string): Promise<void> {
    const zip = new Zip();
    Promise.all<Promise<TDownloadPromise>[]>(promisList).then((blobs) => {
      blobs.forEach(({ promise, folderPath, fileName }, index) => {
        const newFileName = fileName.split("/")[fileName.split("/").length - 1];
        zip.folder(folderPath).file(newFileName, promise);
        if (blobs.length === index + 1) {
          zip.generateAsync({ type: 'blob' })
            .then(function (zipBlob) {
              const zipBlobUrl = URL.createObjectURL(zipBlob);
              const downloadLink = document.createElement('a');
              downloadLink.href = zipBlobUrl;
              downloadLink.download = zipName + '.zip';
              downloadLink.textContent = 'Download Zip';
              document.body.appendChild(downloadLink);
              downloadLink.click();
            })
            .catch(function (error) {
              console.error('Error creating zip:', error);

            });
        }
      });
    });
  }
  generatePathList(response) {
    const promiseList: Array<Promise<TDownloadPromise>> = [];
    for (let outerIndex = 0; outerIndex < response.folders.length; outerIndex++) {
      const folder = response.folders[outerIndex];
      for (let innerIndex = 0; innerIndex < folder.fileList.length; innerIndex++) {
        const fileName = folder.fileList[innerIndex];
        const b = response.blobBasePath + '/' + fileName + response.accessToken;
        promiseList.push(this.returnPromise(b, folder.folderName, fileName));
      }
    }
    return promiseList;
  }
  async returnPromise(url, folderPath, fileName): Promise<TDownloadPromise> {
    const response = await fetch(url);
    const blobData = await response.blob();
    return { promise: blobData, folderPath: folderPath, fileName };
  }

  getCityPermanent() {
    if (this.cityDropdown.length) {
      return;
    }
    const payload = {
      "cityCode": "",
      "stateCode": "",
      "countryCode": "",
      "cityStatus": "1",
      "pagination": {
        "pageNumber": 0,
        "pageSize": 9999
      }
    }
    this._httpService.Post(cityUrls.search, payload).subscribe(response => {
      this.cityDropdown = [];
      if (response) {
        response.forEach(x => {
          this.cityDropdown.push({ 'key': x.cityCode, 'value': [x.cityName, x.stateName, x.countryName].join(','), 'text': x.cityName })
        })
      }
    });
  }

  navigateToCurrentRoute(): void {
    const location = this._location.path();
    const selectedHRDesk = localStorage.getItem('selectedHRDeskSearch');
    if ((this.router.url.includes('from=action') && selectedHRDesk) || this.router.url.includes('/app/processes/search/imports') && selectedHRDesk) {
      localStorage.setItem('selectedEnterpriseSearch', selectedHRDesk);
      localStorage.removeItem('selectedHRDeskSearch');
      if (this.router.url.includes('openedFrom=pin')) {
        this.router.navigate(['/app/processes/search'], { queryParams: { type: 'search', version: 1, openedFrom: 'pin' }, skipLocationChange: true });
      } else {
        this.router.navigate(['/app/processes/search'], { queryParams: { type: 'search', version: 1 }, skipLocationChange: true });
      }
    } else {
      this.router.navigateByUrl(location);
    }
  }
  isEmployeePriviledged(employeeId: number | 'candidate'): Observable<boolean> {
    if (employeeId === 'candidate') {
      return this._httpService.Get(`/api/core/pii/isprivilegeuser`);
    }
    return this._httpService.Get(employeeId ? `/api/core/pii/isprivilegeuser/${employeeId}` : `/api/core/pii/isprivilegeuser/${this.logOnUserDetails.employeeId}`);
  }

  getUserLicenseInfo(): Promise<IUserLicenseInfo> {
    return new Promise((resolve, reject) => {
      if (this.userLicenseInfo) {
        resolve(this.userLicenseInfo);
      } else {
        this._httpService.Get('/api/UserLicense/info').subscribe((res: IUserLicenseInfo) => {
          this.userLicenseInfo = res;
          resolve(this.userLicenseInfo);
        });
      }
    });
  }

  getCommonSettingsList(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.commmonSettingsList.length) {
        resolve();
        return;
      }
      this._httpClient.get('assets/json/common-settings-list.json').subscribe((data: ICommonsetting[]) => {
        this.commmonSettingsList = data;
        resolve();
      });
    });
  }

  canDisplaySettings(menuId: number): ICommonsetting[] {
    const settingList = this.commmonSettingsList.filter(config => config.menuId == menuId);
    if (settingList.length) {
      settingList.forEach(setting => {
        switch (setting.settingType) {
          case 'WO':
            setting['hasAccess'] = this.rawMenusWithActions.findIndex(item => item.accessRightId === 210) >= 0;
            break;
          case 'TO':
            setting['hasAccess'] = this.rawMenusWithActions.findIndex(item => item.accessRightId === 245) >= 0;
            break;
          case 'Payroll':
            setting['hasAccess'] = this.rawMenusWithActions.findIndex(item => item.accessRightId === 420) >= 0;
            break;
          case 'Helpdesk':
            setting['hasAccess'] = this.rawMenusWithActions.findIndex(item => item.accessRightId === 1111) >= 0;
            break;
          case 'ProjectMgmt':
            setting['hasAccess'] = this.rawMenusWithActions.findIndex(item => item.accessRightId === 2074) >= 0;
            break;
          case 'Engage':
            setting['hasAccess'] = this.rawMenusWithActions.findIndex(item => item.accessRightId === 632) >= 0;
            break;
          case 'PMS':
            setting['hasAccess'] = this.rawMenusWithActions.findIndex(item => item.accessRightId === 571) >= 0;
            break;
          case 'Asset':
            setting['hasAccess'] = this.rawMenusWithActions.findIndex(item => item.accessRightId === 971) >= 0;
            break;
          case 'RMS':
            setting['hasAccess'] = this.rawMenusWithActions.findIndex(item => item.accessRightId === 821) >= 0;
            break;
          case 'Expense':
            setting['hasAccess'] = this.rawMenusWithActions.findIndex(item => item.accessRightId === 718) >= 0;
            break;
          default:
            setting['hasAccess'] = false;
        }
      });
    }
    return settingList.find(setting => setting.hasAccess) ? settingList : [];
  }

  getModules(): Observable<TSubscribeModuleResponse> {
    return this._globalState.subscribeModule();
  }

  getTeamIndirectList(): Promise<any> {
    return new Promise((resolve, reject) => {
      const req_data = { reportId: 2, reportingTeam: 'RM', teamSearch: '', pagination: { pageNumber: 1, pageSize: 99999 } };
      if (this.teamIndirectList.length) {
        resolve(this.teamIndirectList);
      } else {
        this._httpService.Post('/api/workforce/Team/Direct/Indirect', req_data)
          .subscribe(
            (response) => {
              this.teamIndirectList = response ? response : [];
              resolve(this.teamIndirectList);
            },
            () => {
              reject([]);
            });
      }
    });
  }

  getNewPmsSetting(): void {
    const url = '/api/pms/setting/general/Extensions';
    this._httpService.Get(url).subscribe((res: PmsSettingType[]) => {
      if (res && res.length) {
        this.isReviewCalculationVisible = Boolean(res.find((ele: PmsSettingType) => ele.generalSettingId === 104 && ele.status === 'Y'));
      }
    });
  }
  sendData(data: boolean): void {
    this.dataSubject.next(data);
  }
  resetData(): void {
    this.dataSubject.next(false);
  }
  onRedirectToOnboarding() {
    const url = '/api/core/onboard/CheckProcess';
    this._httpService.Get(url).subscribe((res: TCoreOnboardCheckProcessResponse) => {
      const pageNumber: number = res?.selectedOnPageNumber ? res?.selectedOnPageNumber : 1;
      if (res?.selectedSegmentTypeId) {
        localStorage.setItem('isModuleSelection', JSON.stringify(true));
        this.router.navigate(['/app/onboarding-checklist', pageNumber, res?.selectedSegmentTypeId]);
      }
      else {
        localStorage.setItem('isGetStart', JSON.stringify(true))
        this.router.navigate(['/onboarding-config', pageNumber])
      }
    })
  }

  getOnboardingSetting(): void {
    this._globalState.getWorkforceSetupSetting(68).subscribe((res: TWorkforceSetupSettingResponse) => {
      this.onboardingAccess = Boolean(this.rawMenus.find(item => item.menuId === 7)) && res?.status === 'Y';
    })
  }

  getIsGoogleDisabled(): Observable<Partial<TWorkforceSetupSettingResponse>> {
    if (this.isGoogleDisabled.loading) return EMPTY;
    if (this.isGoogleDisabled.called) return of({ status: this.isGoogleDisabled.disabled ? 'Y' : 'N' });
    this.isGoogleDisabled.loading = true;
    return this._globalState.getWorkforceSetupSetting(77);
  }

  checkDigiSignLicense(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.digiSignLicenseInfo.apiCalled) {
        resolve();
      } else {
        this.isFeatureAvailable(247).then(res => {
          this.digiSignLicenseInfo.apiCalled = true;
          this.digiSignLicenseInfo.active = res;
          resolve();
        });
      }
    });
  }

  getModuleIconNames(moduleId: string | number): { icon: string, activeIcon: string } {
    switch (moduleId.toString()) {
      case '20': return { icon: 'fa-light fa-party-horn acc-icon', activeIcon: 'fa-duotone fa-party-horn acc-icon' };
      case '1': return { icon: 'fa-light fa-user-group acc-icon', activeIcon: 'fa-duotone fa-user-group acc-icon' };
      case '2': return { icon: 'fa-light fa-calendars acc-icon', activeIcon: 'fa-duotone fa-calendars acc-icon' };
      case '3': return { icon: 'fa-light fa-money-bills-simple acc-icon', activeIcon: 'fa-duotone fa-money-bills-simple acc-icon' };
      case '4': return { icon: 'fa-light fa-chart-line-up-down acc-icon', activeIcon: 'fa-duotone fa-chart-line-up-down acc-icon' };
      case '5': return { icon: 'fa-light fa-receipt acc-icon', activeIcon: 'fa-duotone fa-receipt acc-icon' };
      case '6': return { icon: 'fa-light fa-headphones acc-icon', activeIcon: 'fa-duotone fa-headphones acc-icon' };
      case '7': return { icon: 'fa-light fa-laptop-mobile acc-icon', activeIcon: 'fa-duotone fa-laptop-mobile acc-icon' };
      case '9': return { icon: 'fa-light fa-handshake acc-icon', activeIcon: 'fa-duotone fa-handshake acc-icon' };
      default: return { icon: 'fa-light fa-user acc-icon', activeIcon: 'fa-duotone fa-user acc-icon' };
    }
  }

  getMenuIds(moduleId: string | number): number {
    switch (moduleId.toString()) {
      case '20': return 187;
      case '1': return 38;
      case '2': return 94;
      case '3': return 54;
      case '4': return 410;
      case '5': return 504;
      case '6': return 369;
      case '7': return 736;
      case '9': return 573;
      case '23': return 1000;
      default: return 0;
    }
  }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {

    return !!(control && control.invalid && (control.dirty || control.touched));
  }



}
export type TDownloadPromise = {
  promise: Blob, folderPath: string, fileName: string
}

export interface IUserLicenseInfo {
  activeEssUser: number;
  activeProjectUser: number;
  activeUser: number;
  activeWhatsappUser: number;
  goLiveDate: string;
  isGolive: boolean;
  remainingEssUserLicense: number;
  remainingProjectUserLicense: number;
  remainingUserLicense: number;
  remainingWhatsappUserLicense: number;
  totalEssUserLicense: number;
  totalProjectUserLicense: number;
  totalUserLicense: number;
  totalWhatsappUserLicense: number;
}
type TIdentifierKey = 'grade' | 'company' | 'businessUnit' | 'department' | 'subDepartment' |
  'designation' | 'level' | 'region' | 'branch' | 'subBranch';

export interface ICommonsetting {
  menuId: number;
  settingType: string;
  ids: string;
  hasAccess?: boolean;
}
export type PmsSettingType = {
  settingName: string;
  isActive: boolean;
  controlType: string;
  multipleUpdate: boolean;
  controlDataType: null;
  controlDataTypeSize: number;
  generalSettingId: number;
  status: string;
}

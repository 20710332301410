
/*
 *  This file redirect-to-home-guard.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';

import { AuthService } from '../services/auth-service';

@Injectable()
export class RedirectToHomeGuardService implements CanLoad {

    constructor(
        public _authService: AuthService,
        public _router: Router
    ) {

    }

    canLoad(): boolean {
        if (this._authService.isAuthenticated()) {
            if (window.location.href.includes('/login?kbRedirectUrl=')) {
                const kbRedirectUrl = window.location.search.split('kbRedirectUrl=');
                const token: string = JSON.parse(localStorage.getItem('authlogin'))?.access_token;
                if (kbRedirectUrl.length > 1) {
                    window.open(kbRedirectUrl[1] + '?access_token=' + token + '&auth=' + btoa(this._authService._config.base_auth_url), '_self');
                    return;
                }
            }
            if (window.location.href.includes('/login?redirect_url=')) {
                const redirect_url = window.location.search.split('redirect_url=');
                if (redirect_url.length > 1) {
                  this._authService.getRefreshToken().subscribe({
                    next:(auth)=>{
                      const url = `${redirect_url[1]}?access_token=${auth.access_token}&refresh_token=${auth.refresh_token}`;
                      this._authService.setLoginAuth(auth);
                      window.open(url,'_self') ;
                      return;
                    },
                    error:()=>{
                      localStorage.setItem('redirect_url', redirect_url[1]);
                      this._authService.logout();
                    }
                  })

                }
            }
            const isProcessStart = JSON?.parse(localStorage?.getItem('isProcessStart'))
            const onboardingPageInfo = JSON?.parse(localStorage?.getItem('onboardingPageInfo'))

            if (isProcessStart && onboardingPageInfo) {
                if (onboardingPageInfo?.segmentTypeId) this._router.navigate(['/onboarding-config', onboardingPageInfo?.pageNumber, onboardingPageInfo?.segmentTypeId])
                else this._router.navigate(['/onboarding-config', onboardingPageInfo?.pageNumber])
                return false;
            }
            this._router.navigate(['app']);
            return false;
        } else {
            return true;
        }
    }
}

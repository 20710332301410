/*
 *  This file global-state.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2024
 *  (C) 2015-2024. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */


import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { EGlobalStateProcessId, EGlobalStateStoreNames, GLOBAL_API_URLS, TContestEnableDisableResponse, TCoreFeatureValidate, TWorkforceSetupSettingResponse } from './global.constants';
import { HttpService } from 'src/app/global/services/http-service';
import { FeedPayloadState, IFeedResponse, IFeedResponseExtended } from 'src/app/modules/enterprise-wall/social-enterpise-wall/social-wall.model';
import { TSubscribeModuleResponse } from 'src/app/modules/team/team-dashboards/team-dashboards.model';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { TGlobalState, TGlobalStateProcessVersion } from './global.reducer';
import { catchError, filter, finalize, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { enterpriseEnableDisableSuccess, employeecontestEnableDisableSuccess, loadBadgeFeedSuccess, subscribeModuleSuccess, validateFeatureSuccess, workforceSettingSuccess } from './global.action';
import { LogonUserDetailModel } from 'src/app/global/models/common.models';


@Injectable({
  providedIn: 'root'
})
export class HroneGlobalStateService {
  validateHash = new Map<number, TCoreFeatureValidate>();
  versionhash = new Map<number, string>
  private readonly workforceSetupSettings$: Subject<TWorkforceSetupSettingResponse[]|null> = new Subject();
  private isWorkforceSettingCalled = false;
  constructor(
    private readonly http: HttpService,
    public indexdb: NgxIndexedDBService,
    public store: Store<TGlobalState>,
  ) {}

  handleVersionDetails(value: { currentVersion: string; processId: number; }[]) {
    value.forEach(({ currentVersion, processId }) => {
      this.versionhash.set(processId, currentVersion);
    });
  }

  getVersionDetails(): Observable<Array<{ currentVersion: string, processId: number }>> {
    const bs = new BehaviorSubject([] as Array<{ currentVersion: string, processId: number }>);
    const authStr = localStorage.getItem('authlogin')
    return authStr ? this.http.Get(GLOBAL_API_URLS.VERSION_DETAIL) : bs.asObservable();
  }
  private fetchEnterPriseContestEnableDisable(): Observable<TContestEnableDisableResponse> {
    return this.http.Get(GLOBAL_API_URLS.CONTEST_ENABLE_DISABLE);
  }
  private fetchEmployeeContestEnableDisable(id:number): Observable<{disabled:boolean}> {
    return this.http.Get(`${GLOBAL_API_URLS.EMPLOYEECONTESTENABLEDISABLESTATUS}/${id}`);
  }


  private fetchBadgeFeed(payloadState: FeedPayloadState): Observable<Array<IFeedResponseExtended>> {
    return this.http.Post(GLOBAL_API_URLS.BADGE_FEED, payloadState);
  }


  private fetchSubscriberModules(): Observable<TSubscribeModuleResponse> {
    return this.http.Get(GLOBAL_API_URLS.SUBSCRIBE_MODULE);
  }


  private fetchWorkforceSetupSetting(settingId: number, activeStatus: string): Observable<TWorkforceSetupSettingResponse> {
    return this.http.Get(`${GLOBAL_API_URLS.WORKFORCE_SETUP_SETTING}/${settingId}/${activeStatus}`);
  }


  private fetchAllWorkforceSetupSettings(): Observable<Array<TWorkforceSetupSettingResponse>> {
    this.isWorkforceSettingCalled = true;
    return this.http.Get('/api/workforce/setup/Setting/0,1').pipe(
      tap(()=>{
        this.isWorkforceSettingCalled = false;
      })
    );
  }


  private fetchFeatureValidation(featureId: number): Observable<TCoreFeatureValidate> {
    return this.http.Get(`${GLOBAL_API_URLS.VALIDATE_FEATURE}${featureId}`);
  }

  contestEnableDisable(): Observable<TContestEnableDisableResponse> {
    const { employeeId, domainCode } = this.logOnUserDetails();
    return this.indexdb.getByID<TGlobalStateProcessVersion<{
      data: TContestEnableDisableResponse,
      loading: boolean,
    }>>('hronestore', EGlobalStateProcessId.EnterpriseContest).pipe(
      switchMap(
        (cache) => {
          if (cache && this.shouldGetFromCache(cache.versionId, cache.processId, cache.employeeId, cache.domainCode, cache.date)) {
            return of(cache.data)
          }
          return this.fetchEnterPriseContestEnableDisable().pipe(
            tap(data => {
              this.store.dispatch(enterpriseEnableDisableSuccess({ data, storename: EGlobalStateStoreNames.EnterpriseContest, versionId: this.versionhash.get(EGlobalStateProcessId.EnterpriseContest) ?? "1", domainCode, employeeId }))
            })
          );
        }
      ),
      catchError(() => {
        return this.fetchEnterPriseContestEnableDisable().pipe(
          tap(data => {
            this.store.dispatch(enterpriseEnableDisableSuccess({ data, storename: EGlobalStateStoreNames.EnterpriseContest, versionId: this.versionhash.get(EGlobalStateProcessId.EnterpriseContest) ?? "1", domainCode, employeeId }))
          })
        );
      })
    )

  }
  contestEmployeeEnableDisable(id:number): Observable<{disabled:boolean}> {
    const { employeeId, domainCode } = this.logOnUserDetails();
    return this.indexdb.getByID<TGlobalStateProcessVersion<{
      data: {disabled:boolean},
      loading: boolean,
    }>>('hronestore', EGlobalStateProcessId.EmployeeContest).pipe(
      switchMap(
        (cache) => {
          if (cache && this.shouldGetFromCache(cache.versionId, cache.processId, cache.employeeId, cache.domainCode, cache.date) && 'disabled' in cache.data) {
            return of(cache.data)
          }
          return this.fetchEmployeeContestEnableDisable(id).pipe(
            tap(data => {
              this.store.dispatch(employeecontestEnableDisableSuccess({ data, storename: EGlobalStateStoreNames.EmployeeContest, versionId: this.versionhash.get(EGlobalStateProcessId.EmployeeContest) ?? "1", domainCode, employeeId }))
            })
          );
        }
      ),
      catchError(() => {
        return this.fetchEmployeeContestEnableDisable(id).pipe(
          tap(data => {
            this.store.dispatch(employeecontestEnableDisableSuccess({ data, storename: EGlobalStateStoreNames.EmployeeContest, versionId: this.versionhash.get(EGlobalStateProcessId.EmployeeContest) ?? "1", domainCode, employeeId }))
          })
        );
      })
    )

  }
  getBadgeFeed(payloadState: FeedPayloadState, employeeId: number, domainCode: string, refreshFeedSignalR: boolean | undefined): Observable<Array<IFeedResponseExtended>> {
    if (refreshFeedSignalR) {
      return this.fetchBadgeFeed(payloadState).pipe(
        tap(data => {
          this.store.dispatch(loadBadgeFeedSuccess({ data, storename: EGlobalStateStoreNames.Feed, versionId: this.versionhash.get(EGlobalStateProcessId.Feed) ?? "1", domainCode, employeeId }))
        }),
      );
    }
    return this.indexdb.getByID<TGlobalStateProcessVersion<{
      data: IFeedResponse[],
      loading: boolean,
    }>>('hronestore', EGlobalStateProcessId.Feed).pipe(
      switchMap(
        (cache) => {
          if (cache && this.shouldGetFromCache(cache.versionId, cache.processId, cache.employeeId, cache.domainCode, cache.date)) {
            return of(cache.data)
          }
          return this.fetchBadgeFeed(payloadState).pipe(
            tap(data => {
              this.store.dispatch(loadBadgeFeedSuccess({ data, storename: EGlobalStateStoreNames.Feed, versionId: this.versionhash.get(EGlobalStateProcessId.Feed) ?? "1", domainCode, employeeId }))
            })
          );
        }
      ),
      catchError(() => {
        return this.fetchBadgeFeed(payloadState).pipe(
          tap(data => {
            this.store.dispatch(loadBadgeFeedSuccess({ data, storename: EGlobalStateStoreNames.Feed, versionId: this.versionhash.get(EGlobalStateProcessId.Feed) ?? "1", domainCode, employeeId }))
          })
        );
      })
    )

  }

  subscribeModule(): Observable<TSubscribeModuleResponse> {
    const { employeeId, domainCode } = this.logOnUserDetails();
    return this.indexdb.getByID<TGlobalStateProcessVersion<{
      data: TSubscribeModuleResponse,
      loading: boolean,
    }>>('hronestore', EGlobalStateProcessId.SubscriberModules).pipe(
      switchMap(
        (cache) => {

          if (cache && this.shouldGetFromCache(cache.versionId, cache.processId, cache.employeeId, cache.domainCode, cache.date)) {

            return of(cache.data)
          }
          return this.fetchSubscriberModules().pipe(
            tap(data => {
              this.store.dispatch(subscribeModuleSuccess({ data, storename: EGlobalStateStoreNames.SubscriberModules, versionId: this.versionhash.get(EGlobalStateProcessId.SubscriberModules) ?? "1", domainCode, employeeId }))
            })
          );
        }
      ),
      catchError(() => {
        return this.fetchSubscriberModules()
      })
    )

  }


  logOnUserDetails(): LogonUserDetailModel {
    const userInLocalStoratge = localStorage.getItem('logOnUserDetails');
    return userInLocalStoratge ? JSON.parse(userInLocalStoratge) : {employeeId:null, domainCode:null};
  }

  getWorkforceSetupSetting(settingId: number, activeStatus: '0,1' | '0' | '1' = '0,1'): Observable<TWorkforceSetupSettingResponse | null> {

    const { employeeId, domainCode } = this.logOnUserDetails();
    const sortedActiveStatus = activeStatus ? activeStatus
      .split(',')
      .sort((a, b) => Number(a) - Number(b))
      .join(',') : null;

    return this.indexdb.getByID<TGlobalStateProcessVersion<{
      data: Map<number, TWorkforceSetupSettingResponse>,
      loading: boolean,
    }>>('hronestore', EGlobalStateProcessId.WorkforceSetupSetting).pipe(
      switchMap(cache => {
        if (cache && this.shouldGetFromCache(cache.versionId, cache.processId, cache.employeeId, cache.domainCode, cache.date)) {
          const activeInactive = cache.data.get(settingId);
          if (!activeInactive) {
            return this.fetchWorkforceSetupSetting(settingId, activeStatus)
          }
          else {

            if (sortedActiveStatus === '0,1') {
              return of(activeInactive || null);
            } else if (sortedActiveStatus === '0') {
              const result = activeInactive?.isActive ? null : activeInactive;
              return of(result);
            } else if (sortedActiveStatus === '1') {
              const result = activeInactive?.isActive ? activeInactive : null;
              return of(result);
            }
          }

          return of(null);
        }

        return this.fetchAndCacheAllWorkforceSetupSettings().pipe(
          map((data: TWorkforceSetupSettingResponse[]) => {
            const newData = new Map<number, TWorkforceSetupSettingResponse>();
            let setting: TWorkforceSetupSettingResponse | null = null;

            data.forEach(s => {
              newData.set(s.settingId, s);
              if (s.settingId === settingId) {
                setting = s;
              }
            });

            this.store.dispatch(workforceSettingSuccess({
              data: newData,
              storename: EGlobalStateStoreNames.WorkforceSetupSetting,
              versionId: this.versionhash.get(EGlobalStateProcessId.WorkforceSetupSetting) ?? "1",
              domainCode,
              employeeId
            }));

            return setting;
          }),
          catchError(()=>{
            return this.fetchAllWorkforceSetupSettings().pipe(
              map((data: TWorkforceSetupSettingResponse[]) => {
                this.workforceSetupSettings$.next(data);
                this.isWorkforceSettingCalled = false;
                const newData = new Map<number, TWorkforceSetupSettingResponse>();
                let setting: TWorkforceSetupSettingResponse | null = null;

                data.forEach(s => {
                  newData.set(s.settingId, s);
                  if (s.settingId === settingId) {
                    setting = s;
                  }
                });

                this.store.dispatch(workforceSettingSuccess({
                  data: newData,
                  storename: EGlobalStateStoreNames.WorkforceSetupSetting,
                  versionId: this.versionhash.get(EGlobalStateProcessId.WorkforceSetupSetting) ?? "1",
                  domainCode,
                  employeeId
                }));

                return setting;
              }),
            )
          })
        );
      }),
      catchError(() => {
        return this.fetchAndCacheAllWorkforceSetupSettings().pipe(
          map((data: TWorkforceSetupSettingResponse[]) => {
            const newData = new Map<number, TWorkforceSetupSettingResponse>();
            let setting: TWorkforceSetupSettingResponse | null = null;

            data.forEach(s => {
              newData.set(s.settingId, s);
              if (s.settingId === settingId) {
                setting = s;
              }
            });

            this.store.dispatch(workforceSettingSuccess({
              data: newData,
              storename: EGlobalStateStoreNames.WorkforceSetupSetting,
              versionId: this.versionhash.get(EGlobalStateProcessId.WorkforceSetupSetting) ?? "1",
              domainCode,
              employeeId
            }));

            return setting;
          })
        );
      })
    );

  }


  validateFeature(featureId: number): Observable<TCoreFeatureValidate> {
    const { domainCode, employeeId } = this.logOnUserDetails();
    return this.indexdb.getByID<TGlobalStateProcessVersion<{
      data: Map<number, TCoreFeatureValidate>,
      loading: boolean,
    }>>('hronestore', EGlobalStateProcessId.ValidateFeature).pipe(
      mergeMap(
        (cache) => {
          this.validateHash = cache.data ?? new Map<number, TCoreFeatureValidate>();

          const validity = cache.data.get(featureId);
          if (validity &&
            this.shouldGetFromCache(cache.versionId,EGlobalStateProcessId.ValidateFeature,cache.employeeId,cache.domainCode,cache.date)) {

            return of(validity)
          }
          return this.fetchFeatureValidation(featureId).pipe(
            tap(result => {

              this.validateHash.set(featureId, result);
              this.store.dispatch(validateFeatureSuccess({ data: this.validateHash, storename: EGlobalStateStoreNames.ValidateFeature, versionId: this.versionhash.get(EGlobalStateProcessId.ValidateFeature) ?? "1", domainCode, employeeId }))
            }),
            catchError(err => {
              return throwError(() => err);
            })
          );
        }
      ),
      catchError(() => {
        return this.fetchFeatureValidation(featureId).pipe(
          tap(result => {
            this.validateHash = new Map<number, TCoreFeatureValidate>();
            this.validateHash.set(featureId, result);
            this.store.dispatch(validateFeatureSuccess({ data: this.validateHash, storename: EGlobalStateStoreNames.ValidateFeature, versionId: this.versionhash.get(EGlobalStateProcessId.ValidateFeature) ?? '1', domainCode, employeeId }))
          })
        );
      })
    )

  }

  shouldGetFromCache(versionId: string | undefined,
    processID: number | undefined,
    employeeIdcache: number | undefined,
    domainCodecache: string | undefined, date: string | Date | undefined): boolean {
    const { employeeId, domainCode } = this.logOnUserDetails();
    if (!processID || !date) return false;
    return employeeIdcache === employeeId &&
      domainCode === domainCodecache &&
      this.versionhash.get(processID) === versionId
    // asked to comment by kashish sir.
    // &&
    // this._moment.getMomentFromDate(new Date()).diff(this._moment.getMomentFromDate(date),'hours') < 8;
  }
  private fetchAndCacheAllWorkforceSetupSettings(): Observable<TWorkforceSetupSettingResponse[]> {
    if (!this.isWorkforceSettingCalled) {
      this.workforceSetupSettings$.next(null);
        return this.fetchAllWorkforceSetupSettings().pipe(
          map((data)=>{
            this.workforceSetupSettings$.next(data);
            return data;
          }),
          finalize(() => {
            this.isWorkforceSettingCalled = false;
          })
        )
    }

    return this.workforceSetupSettings$.pipe(
      filter(v => v !== null),
      take(1),
    ) as unknown as  Observable<TWorkforceSetupSettingResponse[]>;
  }

}

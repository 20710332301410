
/* 
 *  This file basehref.util.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022  
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *  
 */

import  {environment} from 'src/environments/environment';

export function getBaseLocationForBaseHref() {
    if (environment['envName'] === 'production') {
        if (isIP(location.origin)) {
            const paths: string[] = location.pathname.split('/').splice(1, 1);
            const basePath: string = (paths && paths[0]) || '';
            return '/' + basePath;
        } else {
            // return location.hostname.split('.')[0];
            return '/';
        }

    } else {
        return '/';
    }
}

export function getDomain() {
    // console.log('Env', environment, environment['envName']);
    if (environment['envName'] === 'production') {
        if (isIP(location.origin)) {
            const paths: string[] = location.pathname.split('/').splice(1, 1);
            const basePath: string = (paths && paths[0]) || '';
            return basePath;
        } else {
            return location.hostname.split('.')[0];
            // return '/';
        }

    } else {
        return environment['domainCode'];
    }
}

export function getDomainHttp(env) {
    // console.log('Env', env, env['envName']);
    if (env['envName'] === 'production') {
        if (isIP(location.origin)) {
            const paths: string[] = location.pathname.split('/').splice(1, 1);
            const basePath: string = (paths && paths[0]) || '';
            return basePath;
        } else {
            return location.hostname.split('.')[0];
            // return '/';
        }

    } else {
        return env['domainCode'];
    }
}


function isIP(address) {
    console.log('address', address);
    const r =
    RegExp([
        '^https?:\/\/([a-z0-9\\.\\-_%]+:([a-z0-9\\.\\-_%])+?@)?',
        '((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\\.){3}(25[0-5]|2[0-4',
        '][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])?',
        '(:[0-9]+)?(\/[^\\s]*)?$'
    ].join(''), 'i');
    return r.test(address);
}


/*
 *  This file app.module.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER, isDevMode } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { getBaseLocationForBaseHref } from './global/util/basehref.util';
import { AuthGuardService } from './global/guard/auth-guard-service';
import { RedirectToHomeGuardService } from './global/guard/redirect-to-home-guard.service';
import { AuthService } from './global/services/auth-service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpService } from './global/services/http-service';
import { SharedService } from './global/services/shared.service';
import { SnackbarAlertService } from './global/services/snackbar-alert.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ClientErrorHandler } from './global/services/error-handler/client-error-handler.service';
import { ServerErrorHandler } from './global/services/error-handler/server-error-handler.service';
import { LogErrorsService } from './global/services/error-handler/log-errors';

import { SnackbarComponent } from './global/components/snackbar/snackbar.component';
import { ProgressButtonService } from './global/services/progress-button.service';

import { PageLoaderService } from './global/services/page-loader.service';
import { LogonUserResolverService } from './global/services/logon-user-resolver.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

import { UserRightsService } from './global/services/user-rights.service';
import { FileUploadService } from './global/services/file-upload.service';
import { SearchEmployeeService } from './global/services/search-employee.service';
import { ConfigurationService } from './global/services/configuration.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { LoadScriptService } from './global/services/load-script.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HroneNetworkInterceptor } from './global/services/interceptor/hrone-network.interceptor';
import { HroneGlobalMobileDisclaimerComponent } from "./global/components/hrone-global-mobile-disclaimer/hrone-global-mobile-disclaimer.component";
import { DialogModule } from 'primeng/dialog';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { getAnalytics, provideAnalytics, ScreenTrackingService } from '@angular/fire/analytics';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { ServiceWorkerModule } from '@angular/service-worker';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { hroneGlobalReducer } from './store/global/global.reducer';
import { HroneGlobalStateEffects } from './store/global/global.effect';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { hroneIndexDbConfig } from './store/global/global.constants';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    SnackbarComponent,
    ConfirmDialogModule,
    OAuthModule.forRoot(),
    StoreModule.forRoot({hroneGlobal:hroneGlobalReducer}, {}),
    EffectsModule.forRoot([HroneGlobalStateEffects]),
    NgxIndexedDBModule.forRoot(hroneIndexDbConfig),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    HroneGlobalMobileDisclaimerComponent,
    DialogModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideMessaging(() => getMessaging()),
    provideAnalytics(() => getAnalytics()),
    // Initialize the second Firebase project
    provideFirebaseApp(() => initializeApp(environment.firebase2, 'projectChat')),
    provideFirestore(() => getFirestore(getApp('projectChat'))),
    provideAuth(() => getAuth(getApp('projectChat'))),
    provideStorage(() => getStorage(getApp('projectChat'))),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    ScreenTrackingService,
    {
      provide: FIREBASE_OPTIONS, useValue: environment.firebase
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HroneNetworkInterceptor,
      multi: true
    },
    AuthGuardService,
    LogonUserResolverService,
    RedirectToHomeGuardService,
    AuthService,
    HttpService,
    SharedService,
    SnackbarAlertService,
    ProgressButtonService,
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseLocationForBaseHref
    },
    {
      provide: ErrorHandler,
      useClass: ClientErrorHandler
    },
    ServerErrorHandler,
    LogErrorsService,
    PageLoaderService,
    ConfirmationService,
    UserRightsService,
    FileUploadService,
    SearchEmployeeService,
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: ConfigurationService) => function () {
        return ds.loadConfig();
      },
      deps: [ConfigurationService],
      multi: true
    },
    LoadScriptService,
    Title,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }



/*
 *  This file hrone-network-helper.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 20203
 *  (C) 2015-20203. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */



import { Injectable, inject } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { TLoginTokenResponse } from '../../models/common.models';
import { ConfigurationService } from '../configuration.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HroneNetworkHelperService {
  _config = inject(ConfigurationService);
  _http = inject(HttpClient);




  private _router = inject(Router);
  apiCalled = false;
  delaywhen$ = new BehaviorSubject(false);
  refreshingToken: boolean;
  tokenBs: BehaviorSubject<TLoginTokenResponse | null> = new BehaviorSubject(this.getAuthLogin());



  public LoginUnAuthorized(url, param): Observable<any> {
    const headers = this.getLoginHeaders();
    headers.delete("Authorization");
    return this._http
      .post(this._config.base_auth_url + url, param, { headers: headers })

  }

  getLoginHeaders() {
    const authlogin = this.getAuthLogin();
    const accessToken = authlogin ? authlogin.access_token : "";
    const dc = localStorage.getItem('domainCode') ? localStorage.getItem('domainCode') : this._config.domainCode
    return new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
      domainCode: dc,
      Authorization: "Bearer " + accessToken,
      AccessMode: 'W'
    });
  }

  getRefreshToken(): Observable<TLoginTokenResponse> {
    const authlogin = this.getAuthLogin();
    const url = "/oauth2/token";
    const logOnUserDetails = JSON.parse(localStorage.getItem('logOnUserDetails'));
    const username = localStorage.getItem('logOnUserName') ? localStorage.getItem('logOnUserName') : logOnUserDetails ? logOnUserDetails.officialEmail : '';
    const body = `refreshId=${authlogin ? authlogin.refresh_token:''}&companyDomainCode=${logOnUserDetails ? logOnUserDetails.domainCode:''}&grant_type=refresh_token&username=${username||''}`;
    return this.LoginUnAuthorized(url, body);
  }
  logout() {
    this.logOutFromWeb();
  }
  setLoginAuth(data) {
    localStorage.setItem("authlogin", JSON.stringify(data));
  }

  clearLocalStorage() {
    localStorage.removeItem('authlogin');
    localStorage.removeItem('rawMenusWithActions');
    localStorage.removeItem('socketId');
    localStorage.removeItem('rawMenus');
    localStorage.removeItem('logOnUserDetails');
    localStorage.removeItem('whiteLabels');
    this._router.navigate(['./login']);
  }

  logOutFromWeb() {
    const authlogin = this.getAuthLogin();
    const refresh_token = authlogin?.refresh_token ?? '';
    const firebaseId = localStorage.getItem('fcmToken') ?? '';
    const url = `/api/LogOnUser/Logout`;
    const userDetails = JSON.parse(localStorage.getItem('logOnUserDetails'));
    if(!refresh_token){
      this.handleLogout();
      return;
    }
    this._http.put(this._config.base_url + url,{refreshId:refresh_token,firebaseId,domainCode:userDetails ? userDetails.domainCode: '',logOnUserId:userDetails ? userDetails.employeeId:''})
    .subscribe({
      next:()=>{
        this.handleLogout();
      },
      error:()=>{
        this.handleLogout();
      }
    });

  }


  private handleLogout() {
    this.clearLocalStorage();
    localStorage.removeItem('launchDialog');
  }

  getAuthLogin(): TLoginTokenResponse | null {
    return JSON.parse(localStorage.getItem("authlogin"));
  }

}

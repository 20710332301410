
/*
 *  This file search-employee.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Injectable } from '@angular/core';
import { Observable, Subscription,BehaviorSubject } from 'rxjs';
import { HttpService } from './http-service';
import { AutoComplete } from 'primeng/autocomplete';
import { first } from 'rxjs/operators';
import { SharedService } from './shared.service';
import { SnackbarAlertService } from './snackbar-alert.service';

@Injectable()
export class SearchEmployeeService {

    searchedEmployeeList: any[] = [];
    searchedCollaboratorList: any[] = [];
    selectedEmployeeList: any[] = [];
    subscription: Subscription;
    searchedJobOpeningList: any[] = []
    teamSubscription: Subscription;

    searchLabel = 'Search by name or code or designation or email id';
    candSearchLabel = 'Search by name or id or mobile no. or email id';

    constructor(
        private _httpService: HttpService,
        private _shared: SharedService,
        public _snkbr: SnackbarAlertService
    ) { }

    searchEmployee(query, model: AutoComplete, status?, isDirectoryFilterApplicable = 0, isProfilePrivate?):Observable<any> {
      const bs = new BehaviorSubject(false);
      const stat = status === undefined ? '1' : String(status);
        if (model) {
            model.loading = false;
        }
        if (query && query.trim().length > 1 && query.trim().length < 101) {
            const queryTrimValue = query.trim();
            const url = '/api/core/EmployeeAutoSearch';
            const reqData = {
                searchString: queryTrimValue,
                activeStatus: stat,
                isDirectoryFilterApplicable: isDirectoryFilterApplicable,
                isProfilePrivate: isProfilePrivate ? isProfilePrivate : false
            };
            if (model) {
                model.loading = true;
            }
            if (this.subscription) {
                this.subscription.unsubscribe();
            }
            this.subscription =
            this._httpService.Post(url, reqData)
                    .subscribe(
                        response => {
                            this.setCollaborator(response);
                            this.setEmpList(response);
                            bs.next(true);
                        },
                        err => {
                            this.onError(model);
                            bs.next(true);
                        });
        } else {
            if (query.trim().length > 100) {
                this._snkbr.Alert(`Allowed characters limit is 100`, '3');
            }
        }
        return bs.asObservable();
    }


    searchEmployeeIncludingExternalEmp(query, model: AutoComplete, isIncludeExternalEmployee: true | false, status?, isOnlyExternalEmployee?: true | false, isReplaceEmployee?: true | false) {
        const stat = status === undefined ? '1' : String(status);
        if (model) {
            model.loading = false;
        }
        if (query && query.trim().length > 1 && query.trim().length < 101) {
            const queryTrimValue = query.trim();
            const url = '/api/core/EmployeeAutoSearch';
            const reqData = {
                searchString: queryTrimValue,
                activeStatus: stat,
                isIncludeExternalEmployee: isIncludeExternalEmployee,
                isOnlyExternalEmployee: isOnlyExternalEmployee ? 1 : 0,
                isReplaceEmployee: isReplaceEmployee ? 1 : 0
            };
            if (model) {
                model.loading = true;
            }
            if (this.subscription) {
                this.subscription.unsubscribe();
            }
            this.subscription =
                this._httpService.Post(url, reqData)
                    .subscribe(
                        response => {
                            this.setEmpList(response);
                        },
                        err => {
                            this.onError(model);
                        });
        } else {
            if (query.trim().length > 100) {
                this._snkbr.Alert(`Allowed characters limit is 100`, '3');
            }
        }
    }

    searchCandidate(query, model: AutoComplete) {
        if (model) {
            model.loading = false;
        }
        if (query && query.trim().length > 1 && query.trim().length < 101) {
            const queryTrimValue = query.trim();
            const url = '/api/recruitment/CandidateProfile/Search';
            const reqData = {
                searchValue: queryTrimValue,
            };
            if (model) {
                model.loading = true;
            }
            if (this.subscription) {
                this.subscription.unsubscribe();
            }
            this.subscription =
                this._httpService.Post(url, reqData)
                    .subscribe(
                        response => {
                            this.setCandidateList(response);
                        },
                        err => {
                            this.onError(model);
                        });
        } else {
            this._snkbr.Alert(`Allowed characters limit is 100`, '3');
        }
    }

    searchEmpCompanyWise(query, model: AutoComplete, status, ignoreSelf?: boolean) {
        const stat = String(status);
        if (model) {
            model.loading = false;
        }
        if (query && query.trim().length > 1) {
            const queryTrimValue = query.trim();
            const url = '/api/core/EmployeeAutoSearch';
            const reqData2 = {
                searchString: queryTrimValue,
                activeStatus: stat,
                companyCode: this._shared.logOnUserDetails.companyCode
            };
            if (model) {
                model.loading = true;
            }
            if (this.subscription) {
                this.subscription.unsubscribe();
            }
            this.subscription =
                this._httpService.Post(url, reqData2)
                    .subscribe(
                        response2 => {
                            if (ignoreSelf) {
                                const newRes = response2?.filter(item => item.employeeId !== this._shared.logOnUserDetails?.employeeId);
                                this.setEmpList(newRes);
                            }
                            else {
                                this.setEmpList(response2);
                            }
                        },
                        err => {
                            this.onError(model);
                        });
        }
    }

    // isSelfInclude - pass 1 when self search
    searchEmployeeForTeam(query, model: AutoComplete, isSelfInclude?: number):Observable<any> {
      const bs = new BehaviorSubject(false);
        const empId = this._shared.logOnUserDetails.employeeId;
        if (model) {
            model.loading = false;
        }
        if (query && query.trim().length > 1) {
            const queryTrimValue = query.trim();
            const url = isSelfInclude ? `/api/workforce/Team/Search/Reporting/${empId}/${queryTrimValue}/${isSelfInclude}` : '/api/workforce/Team/Search/Reporting?employeeId=' + empId + '&searchEmployee=' + queryTrimValue;
            if (model) {
                model.loading = true;
            }
            if (this.teamSubscription) {
                this.teamSubscription.unsubscribe();
            }
            this.teamSubscription =
                this._httpService.Get(url)
                    .subscribe(
                        res => {
                            this.setEmpList(res);
                            bs.next(true);
                        },
                        err => {
                            this.onError(model);
                            bs.next(true);
                        });
        }
        return bs.asObservable();
    }

    setEmpList(res) {
        const list = res ? res : [];
        this.searchedEmployeeList = list.map(elem => {
            return {
                name: elem.employeeName,
                code: elem.employeeCode,
                id: elem.employeeId,
                codeName: elem.employeeName + '  #' + elem.employeeCode,
                designation: elem.designation,
                imageVirtualPath: elem.imageVirtualPath,
                officialEmail: elem.officialEmail ? elem.officialEmail : '',
                personalEmail: elem.personalEmail ? elem.personalEmail : '',
                mobile: elem.mobileNo ? elem.mobileNo : '',
            };
        });
    }

    setCollaborator(res) {
        const list = res ? res : [];
        this.searchedCollaboratorList = list.map(elem => {
            return {
                employeeName: elem.employeeName,
                employeeCode: elem.employeeCode,
                employeeId: elem.employeeId,
                codeName: elem.employeeName + '    #' + elem.employeeCode
            };
        });
    }

    setCandidateList(res) {
        const list = res ? res : [];
        this.searchedEmployeeList = list.map(elem => {
            return {
                name: elem.candidateName + "( # " + elem.candidateId + " )",
                id: elem.candidateId,
                mobile: elem.mobileNo,
                email: elem.personalEmail,
                codeName: elem.candidateName,
            };
        });
    }
    onError(model) {
        this.searchedEmployeeList = [];
        if (model) {
            model.loading = false;
        }
    }


    getEmployeeInfo(empId): Promise<any> {
        const url = '/api/workforce/Employee/EmployeeInformation/' + empId;

        return new Promise((resolve, reject) => {
            this._httpService.Get(url).pipe(first()).
                subscribe(
                    (response) => {
                        const empInfo = response ? response[0] : [];
                        resolve(empInfo);
                    }, err => {
                        reject(new Error("error!"));
                    });

        });
    }
    isActiveEmployee(empId) {
        return new Promise((resolve, reject) => {
            this._httpService.Get(`/api/workforce/Employee/IsActive?employeeId=${empId}`).subscribe(response => {
                resolve(response);
            }, error => {
                reject(error);
            })
        });
    }

    searchEmployeeForInitiative(query, model: AutoComplete) {
        const empId = this._shared.logOnUserDetails.employeeId;
        if (model) {
            model.loading = false;
        }
        if (query && query.trim().length > 1) {
            const queryTrimValue = query.trim();
            const payLoad = {
                "employeeId": empId,
                "searchEmployee": queryTrimValue,
                "isSelfInclude": 1
            }
            const url = '/api/workforce/Team/Search/Reporting';
            if (model) {
                model.loading = true;
            }
            if (this.teamSubscription) {
                this.teamSubscription.unsubscribe();
            }
            this.teamSubscription =
                this._httpService.Post(url, payLoad)
                    .subscribe(
                        res => {
                            this.setEmpList(res);
                        },
                        err => {
                            this.onError(model);
                        });
        }
    }
    searchJobOpening(query, model: AutoComplete) : void{
        if (model) {
            model.loading = false;
        }
        if (query && query.trim().length > 1 && query.trim().length < 101) {
            const queryTrimValue = query.trim();
            const url = '/api/recruitment/JobOpening/Search';
            const reqData = {
                searchValue: queryTrimValue,
            };
            if (model) {
                model.loading = true;
            }
            if (this.subscription) {
                this.subscription.unsubscribe();
            }
            this.subscription =
                this._httpService.Post(url, reqData)
                    .subscribe(
                        response => {
                            this.setJobOpeningList(response);
                        },
                        err => {
                            this.onError(model);
                        });
        } else {
            this._snkbr.Alert(`Allowed characters limit is 100`, '3');
        }
    }
    setJobOpeningList(res) : void {
        const list = res ? res : [];
        this.searchedJobOpeningList = list.map(elem => {
            return {
                key: elem.requestId,
                value: elem.jobTitle,
                jobCode : elem.jobCode
            };
        });
    }

    onEmployeeSearch(event, model: AutoComplete){
        if (model) {
            model.loading = false;
        }
        const url = `/api/core/Project/EmployeeAutoSearch/${event.query}/1`;
        if(event.query) {
            if (model) {
                model.loading = true;
            }
            this._httpService.Get(url).subscribe(res=>{
            if(res?.length)
            {
                this.setEmpList(res);
            }
             else {
                this.searchedEmployeeList = []
            }

            })
        }
      }
}

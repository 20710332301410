/*
 *  This file global.constants.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2024
 *  (C) 2015-2024. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */



import { DBConfig } from 'ngx-indexed-db';
import { TCoreMenuAccessTeamActionsForPin } from 'src/app/main/sidebar/sidebar.model';

export const GLOBAL_API_URLS = {
  REFRESH_PAGE_ACCESS_RIGHT: '/api/LogOnUser/refresh/LogOnUser/PageAccessRight/W',
  CONTEST_ENABLE_DISABLE: '/api/Contest/ContestEnableDisable/',
  BADGE_FEED: '/api/Engage/Badge/Feed',
  SUBSCRIBE_MODULE: '/api/SubscribeModule/Module',
  WORKFORCE_SETUP_SETTING: '/api/workforce/setup/Setting/',
  VALIDATE_FEATURE: '/api/core/feature/Validate/',
  VERSION_DETAIL: '/api/core/DataVersioning/VersionDetail',
  EMPLOYEECONTESTENABLEDISABLESTATUS: `/api/Contest/EmployeeContestEnableDisableStatus`,
};


export type TWorkforceSetupSettingResponse = {
  multipleUpdate: boolean;
  settingId: number;
  settingName: string;
  status: string;
  isActive: boolean;
  controlType: "radiobutton" | "textbox" | "dropdown";
}
export type TCoreFeatureValidate = {
  id: number;
  moduleId: number;
  featureId: number;
  menuIds: string;
  isActive: number;
  numberOfUser: number;
  totalLicenseNumber: number;
  usedLicenseNumber: number;
  message: string;
}

export type TPageAccessRightStoreData = {
  refresh: boolean,
  menuAction: TCoreMenuAccessTeamActionsForPin|null,
    menuList:TCoreMenuAccessResponse[]|null,
    menuAccess:TCoreMenuAccessResponse[]|null,
};
export type TContestEnableDisableResponse = {
  contestId: number;
  result: boolean;
  backgroudImageUrl: string;
  backgroudImageMobileUrl: string;
  htmlTag: string;
  footerTag: string;
  followLink: string;
  knowMoreLink: string;
  htmlData: HTMLData;
}

export type HTMLData = {
  heading: string;
  subheading: string;
  label: string[];
  bulletHeading: string;
  bullet: string[];
  bulletFooter: string;
}


export enum EGlobalStateStoreNames {
  Feed = 'feed',
  EmployeeContest = 'employeecontest',
  PageAccessRights = 'pageaccessrights',
  SubscriberModules = 'subscribermodules',
  ValidateFeature = 'validatefeature',
  WorkforceSetupSetting = 'workforcesetupsetting',
  EnterpriseContest = 'enterprisecontest',
}
export enum EGlobalStateProcessId {
  PageAccessRights = 1,
  EmployeeContest = 2,
  Feed = 3,
  SubscriberModules = 4,
  WorkforceSetupSetting = 5,
  ValidateFeature = 6,
  EnterpriseContest = 7,
}
export type TCoreMenuAccessPayload = {
  menuType: 'P'|'A';
  parentKey: number;
  menuId: number;
  includeMenu: boolean;
}
export type TCoreMenuAccessResponse = {
  menuName:       string;
  sequenceNumber: number;
  menuId:         number;
  moduleId:       number;
  parentKey:      number;
  menuType:       MenuType;
  iconImagePath:  IconImagePath;
  navigationPath: null;
  activeStatus:   string;
  tooltipText:    string;
  controlKey:     null;
  cssClassName:   null | string;
  includeMenu:    boolean;
  navigationCode: string;
  isPin:          boolean;
}

type IconImagePath = "Icon" | "icon" | "Currency";

type MenuType = "P" | "p";
export const hroneIndexDbConfig: DBConfig = {
  name: 'hroneLocalStorageDb',
  version: 1,
  objectStoresMeta: [
    {
      store: 'hronestore',
      storeConfig: { keyPath: 'processId', autoIncrement: false },
      storeSchema: [
        { name: 'processId', keypath: 'processId', options: { unique: true } },
        { name: 'versionId', keypath: 'versionId', options: { unique: false } },
        { name: 'data', keypath: 'data', options: { unique: false } },
        { name: 'employeeId', keypath: 'employeeId', options: { unique: false } },
        { name: 'domainCode', keypath: 'domainCode', options: { unique: false } },
        { name: 'date', keypath: 'date', options: { unique: false } },
      ]
    }
  ]
};

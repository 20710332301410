
/*
 *  This file single-signon.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Injectable, NgZone } from '@angular/core';

import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { LoadScriptService } from '../global/services/load-script.service';
import { Subject } from 'rxjs';
import { Configuration, UserAgentApplication } from 'msal'

declare let gapi: any;

const msalConfig: Configuration = {
    auth: {
        clientId: "",
        authority: "https://login.microsoftonline.com/organizations",
        redirectUri: "",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    }
};
const loginRequest = {
    scopes: ["openid", "profile", "User.Read"]
};
const tokenRequest = {
    scopes: ["Mail.Read"]
};


@Injectable({ providedIn: 'root' })
export class SingleSignonService {

    msalAccessToken$: Subject<any> = new Subject();

    authCodeFlowConfig: AuthConfig = {
        redirectUri: window.location.origin + '/login',
        clientId: '14bf41da-b7be-410a-aac3-04beebc699d5',
        loginUrl: 'https://serv.sai.in/adfs/oauth2/authorize',
        issuer: 'http:localhost:4200',
        scope: 'openid profile',
        responseType: 'id_token token',
        showDebugInformation: true
    };

    constructor(
        public oauthService: OAuthService,
        private _loadScript: LoadScriptService,
        private _zone: NgZone
    ) { }

    onSignIn(authorizationPath = '') {
        const adSetting: any = JSON.parse(localStorage.getItem('adSetting'));
        if (!adSetting) {
            return;
        }
        localStorage.removeItem("authlogin");
        this.authCodeFlowConfig.clientId = adSetting.clientId;
        this.authCodeFlowConfig.loginUrl = authorizationPath ? authorizationPath : adSetting.authorizationPath;
        this.authCodeFlowConfig.issuer = adSetting.redirectPath;
        this.oauthService.configure(this.authCodeFlowConfig);
        this.oauthService.initImplicitFlow();
    }

    adLogout() {
        const loginType = localStorage.getItem('loginType');
        const ad_token_id = localStorage.getItem('ad_token_id');
        const adSetting: any = JSON.parse(localStorage.getItem('adSetting'));
        if (loginType && loginType == 'ad' && ad_token_id && adSetting) {
            this.oauthService.logOut();
            window.location.href = adSetting.logOffPath + ad_token_id;
        }
    }

    onGoogleLogout() {

        this._loadScript.load('googlePlatform').then(data => {
            setTimeout(() => {
                if (!gapi.auth2) {
                    const adSetting: any = JSON.parse(localStorage.getItem('adSetting'));
                    if (!adSetting) {
                        return;
                    }
                    gapi.load("auth2", () => {
                        this._zone.run(() => {
                            gapi.auth2.init({
                                client_id: adSetting.clientId,
                                cookiepolicy: "single_host_origin",
                                scope: "profile email"
                            });
                            this.googleLogoutFurther();
                        });
                    });
                } else {
                    this.googleLogoutFurther();
                }
            }, 500);
        }).catch();

    }

    googleLogoutFurther() {
        setTimeout(() => {
            this._zone.run(() => {
                const auth2 = gapi.auth2.getAuthInstance();
                auth2.disconnect();
                auth2.signOut();
            });
        }, 500);
    }

    getMsalConfig() {
        const adSetting: any = JSON.parse(localStorage.getItem('adSetting'));
        if (!adSetting) {
            return;
        }
        msalConfig.auth.clientId = adSetting.clientId;
        msalConfig.auth.redirectUri = adSetting.redirectPath;

        return msalConfig;
    }

    onMsalLogin() {
        const msConfig = this.getMsalConfig();
        if (!msConfig) {
            return;
        }


        const myMSALObj = new UserAgentApplication(msConfig);
        myMSALObj.loginPopup(loginRequest)
            .then(() => {
                if (myMSALObj.getAccount()) {
                    this.getMsalToken(myMSALObj);
                }
            })

    }

    getMsalToken(myMSALObj) {
        myMSALObj.acquireTokenSilent(loginRequest)
            .then(res => {
                this.msalAccessToken$.next(res.accessToken);
            })
            .catch(error => {
                myMSALObj.acquireTokenPopup(loginRequest)
                    .then(tokenResponse => {
                        this.msalAccessToken$.next(tokenResponse.accessToken);
                    }).catch(error2 => {
                    });
            });
    }

    msalLogout() {
        const msConfig = this.getMsalConfig();
        if (!msConfig) {
            return;
        }

        // this._loadScript.load('msal').then(data => {
        // setTimeout(() => {
        const myMSALObj = new UserAgentApplication(msConfig);
        myMSALObj.logout();
        // }, 300);
        // });
    }

}

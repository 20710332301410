
/*
 *  This file common-dropdown.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Injectable } from '@angular/core';

import { HttpService } from '../http-service';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import { TSubscribeModuleResponse } from 'src/app/modules/team/team-dashboards/team-dashboards.model';
import { TKeyValue } from '../../models/common.models';
import { HroneGlobalStateService } from 'src/app/store/global/global-state.service';

export const commonDdUrls = {
    currency: '/api/core/Currency',
    gender: '/api/core/Gender',
    skills: '/api/core/Skills',
    bloodGroups: '/api/core/BloodGroups',
    nationality: '/api/core/Nationality',
    employeeType: '/api/core/EmployeeType',
    employeeStatus: '/api/core/EmployeeStatus',
    specificEmpStatus: '/api/core/EmployeeStatus/Specific',
    salutation: '/api/core/Salutation',
    maritalStatus: '/api/core/MaritalStatus',
    parentsMediclaim: '/api/core/ParentsMediclaim',
    qualification: '/api/core/Qualification',
    qualificationType: '/api/core/QualificationType',
    specialization: '/api/core/Specialization',
    nominationType: '/api/core/NominationType',
    relationshipType: '/api/core/Relationship',
    documentType: '/api/workforce/Document/Type',
    industryType: '/api/core/IndustryType',
    months: '/api/payroll/MonthYear/',  // add parameter like -1, 0, etc
    monthList: '/api/core/global/Month',
    yearList: '/api/core/global/Year',
    payMode: '/api/core/PayMode',
    banks: '/api/core/Bank',
    branchesBankWise: '/api/core/BankBranch/BindDropDown',
    weekDays: '/api/core/global/Weekdays',
    financialYear: '/api/payroll/FinancialYear',
    otherStatus: `/api/core/EmployeeOtherStatus`
};

@Injectable({
    providedIn: 'root'
})
export class CommonDropdownService {

    moduleDd: any[] = [];
    currencyDd: any[] = [];
    genderDd: any[] = [];
    skillsDd: any[] = [];
    bloodGroupsDd: any[] = [];
    nationalityDd: any[] = [];
    employeeTypeDd: any[] = [];
    employeeStatusDd: any[] = [];
    specificEmpStatusDd: any[] = [];
    specificEmpOtherStatusDd: any[] = [];
    salutationDd: any[] = [];
    maritalStatusDd: any[] = [];
    parentsMediclaimDd: any[] = [];
    qualificationDd: any[] = [];
    qualificationTypeDd: any[] = [];
    specializationDd: any[] = [];
    nominationTypeDd: any[] = [];
    relationshipTypeDd: any[] = [];
    documentTypeDd: any[] = [];
    industryTypeDd: any[] = [];
    monthDd: any[] = [];
    monthsDd: any[] = [];  // genral
    monthOnlyDd: any[] = [];
    yearOnlyDd: any[] = [];
    payModeDd: any[] = [];
    bankDd: any[] = [];
    branchBankWiseDd: any[] = [];
    religionDd: any[] = [];
    weekDaysDd: any[] = [];
    financialYearDd: any[] = [];
    currentFinancialYear: any;

    constructor(
        private _httpService: HttpService,
        public _globalStateService:HroneGlobalStateService,
    ) { }

    getModuleDropDown(): Promise<Array<TKeyValue>> {
        this.moduleDd = [];

        return new Promise((resolve, reject) => {
            this._globalStateService.subscribeModule().pipe(first()).
                subscribe(
                    (response:TSubscribeModuleResponse) => {
                        const list = response.subscribeModule ? response.subscribeModule : [];
                        this.moduleDd = _.sortBy(list.map(mapper => ({
                            value: mapper.moduleName, key: mapper.moduleId,
                        })), [e => e.value.toLowerCase()]);

                        resolve(this.moduleDd);
                    }, err => {
                        reject(new Error("error!"));
                    });

        });
    }

    getCurrencyDropDown() {

        if (this.currencyDd.length) {
            return;
        }

        this._httpService.Get(commonDdUrls.currency).subscribe((response) => {
            this.currencyDd = _.sortBy(response.map(mapper => ({
                value: mapper.text, key: mapper.id,
            })), [e => e.value.toLowerCase()]);
        });
    }

    getGenderDropDown() {

        if (this.genderDd.length) {
            return;
        }

        this._httpService.Get(commonDdUrls.gender).subscribe((response) => {
            this.genderDd = _.sortBy(response.map(mapper => ({
                value: mapper.text, key: mapper.id,
            })), [e => e.value.toLowerCase()]);
        });
    }

    getSkillsDropDown() {

        if (this.skillsDd.length) {
            return;
        }

        this._httpService.Get(commonDdUrls.skills).subscribe((response) => {
            this.skillsDd = _.sortBy(response.map(mapper => ({
                value: mapper.text, key: mapper.id,
            })), [e => e.value.toLowerCase()]);
        });
    }

    getBloodGroupDropDown() {

        if (this.bloodGroupsDd.length) {
            return;
        }

        this._httpService.Get(commonDdUrls.bloodGroups).subscribe((response) => {
            this.bloodGroupsDd = _.sortBy(response.map(mapper => ({
                value: mapper.text, key: mapper.id,
            })), [e => e.value.toLowerCase()]);
        });
    }

    getNationalityDropDown() {
        if (this.nationalityDd.length) {
            return;
        }

        this._httpService.Get(commonDdUrls.nationality).subscribe((response) => {
            this.nationalityDd = _.sortBy(response.map(mapper => ({
                value: mapper.text, key: mapper.id,
            })), [e => e.value.toLowerCase()]);
        });
    }

    getEmployeeTypeDropDown() {
        return new Promise((resolve, reject) => {

            if (this.employeeTypeDd.length) {
                resolve(true);
                return;
            }

            this._httpService.Get(commonDdUrls.employeeType).subscribe((response) => {
                this.employeeTypeDd = _.sortBy(response.map(mapper => ({
                    value: mapper.text, key: mapper.id,
                })), [e => e.value.toLowerCase()]);

                resolve(true);
            });

        });
    }

    getEmployeeStatusDropDown(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this.employeeStatusDd.length) {
                this._httpService.Get(commonDdUrls.employeeStatus).toPromise().then((response) => {
                    this.employeeStatusDd = response.map(mapper => ({
                        value: mapper.text, key: mapper.id,
                    }));

                    resolve(this.employeeStatusDd);
                });
            } else {
                resolve(this.employeeStatusDd);
            }
        });
    }

    getSpecificEmpStatusDropDown(): Promise<any> {

        return new Promise((resolve, reject) => {

            if (!this.specificEmpStatusDd.length) {
                this._httpService.Get(commonDdUrls.specificEmpStatus).toPromise().then((response) => {
                    this.specificEmpStatusDd = response.map(mapper => ({
                        value: mapper.text, key: mapper.id,
                    }));

                    resolve(this.specificEmpStatusDd);
                });
            } else {
                resolve(this.specificEmpStatusDd);
            }

        });
    }

    getSalutationDropDown() {

        if (this.salutationDd.length) {
            return;
        }

        this._httpService.Get(commonDdUrls.salutation).subscribe((response) => {
            this.salutationDd = _.sortBy(response.map(mapper => ({
                value: mapper.text, key: mapper.id,
            })), [e => e.value.toLowerCase()]);
        });
    }

    getMaritalStatusDropDown() {

        if (this.maritalStatusDd.length) {
            return;
        }

        this._httpService.Get(commonDdUrls.maritalStatus).subscribe((response) => {
            this.maritalStatusDd = _.sortBy(response.map(mapper => ({
                value: mapper.text, key: mapper.id,
            })), [e => e.value.toLowerCase()]);
        });
    }

    getParentsMediclaimDropDown() {

        if (this.parentsMediclaimDd.length) {
            return;
        }

        this._httpService.Get(commonDdUrls.parentsMediclaim).subscribe((response) => {
            this.parentsMediclaimDd = _.sortBy(response.map(mapper => ({
                value: mapper.text, key: mapper.id,
            })), [e => e.value.toLowerCase()]);
        });
    }

    getQualificationDropDown() {

        if (this.qualificationDd.length) {
            return;
        }

        this._httpService.Get(commonDdUrls.qualification).subscribe((response) => {
            this.qualificationDd = _.sortBy(response.map(mapper => ({
                value: mapper.text, key: mapper.id, isOther : mapper.isOther
            })), [e => e.value.toLowerCase()]);
        });
    }

    getQualificationTypeDropDown() {

        if (this.qualificationTypeDd.length) {
            return;
        }

        this._httpService.Get(commonDdUrls.qualificationType).subscribe((response) => {
            this.qualificationTypeDd = _.sortBy(response.map(mapper => ({
                value: mapper.text, key: mapper.id,
            })), [e => e.value.toLowerCase()]);
        });
    }

    getSpecializationDropDown(qualificationId) {

        if (this.specializationDd.length) {
            return;
        }

        const url = commonDdUrls.specialization + '/?qualificationCode=' + qualificationId;
        this._httpService.Get(url).subscribe((response) => {
            this.specializationDd = _.sortBy(response.map(mapper => ({
                value: mapper.text, key: mapper.id,
            })), [e => e.value.toLowerCase()]);
        });
    }

    getNominationTypeDropDown() {

        if (this.nominationTypeDd.length) {
            return;
        }

        this._httpService.Get(commonDdUrls.nominationType).subscribe((response) => {
            this.nominationTypeDd = _.sortBy(response.map(mapper => ({
                value: mapper.text, key: mapper.id,
            })), [e => e.value.toLowerCase()]);
        });
    }

    getRelationshipTypeDropDown() {

        if (this.relationshipTypeDd.length) {
            return;
        }

        this._httpService.Get(commonDdUrls.relationshipType).subscribe((response) => {
            this.relationshipTypeDd = _.sortBy(response.map(mapper => ({
                value: mapper.text, key: mapper.id,
            })), [e => e.value.toLowerCase()]);
        });
    }

    getDocumentTypeDropDown() {

        if (this.documentTypeDd.length) {
            return;
        }

        this._httpService.Get(commonDdUrls.documentType).subscribe((response) => {
            this.documentTypeDd = _.sortBy(response.map(mapper => ({
                value: mapper.text, key: mapper.id,
            })), [e => e.value.toLowerCase()]);
        });
    }
    getIndustryTypeDropDown() {

        if (this.industryTypeDd.length) {
            return;
        }

        this._httpService.Get(commonDdUrls.industryType).subscribe((response) => {
            this.industryTypeDd = _.sortBy(response.map(mapper => ({
                value: mapper.text, key: mapper.id,
            })), [e => e.value.toLowerCase()]);
        });
    }

    getPayModeDropDown() {

        if (this.payModeDd.length) {
            return;
        }

        this._httpService.Get(commonDdUrls.payMode).subscribe((response) => {
            const list = response ? response : [];
            this.payModeDd = _.sortBy(list.map(mapper => ({
                value: mapper.text, key: mapper.id,
            })), [e => e.value.toLowerCase()]);
        });
    }

    getBankDropDown() {

        if (this.bankDd.length) {
            return;
        }

        this._httpService.Get(commonDdUrls.banks).subscribe((response) => {
            let list = response ? response : [];
            list = _.sortBy(list, [e => e.bankName.toLowerCase()]);
            const filteredList = list.filter(data => data.isActive);
            this.bankDd = filteredList.map(mapper => ({ value: mapper.bankName, key: mapper.bankCode }));
        });
    }

    getBranchBankWiseDropDown(bankCode) {
        this.branchBankWiseDd = [];

        const reqBody = {
            "bankBranchCode": "",
            "bankCode": bankCode,
            "bankBranchStatus": "1",
            "pagination": {
                "pageNumber": 0,
                "pageSize": 0
            }
        };

        this._httpService.Post(commonDdUrls.branchesBankWise, reqBody).subscribe((response) => {
            const list = response ? response : [];
            this.branchBankWiseDd = _.sortBy(list.map(mapper => ({
                value: mapper.branchName, key: mapper.bankBranchCode, ifsc: mapper.indianFinancialSystemCode,
                bsrc: mapper.branchBasicStatisticalReturnsCode
            })), [e => e.value.toLowerCase()]);
        });
    }

    // get financial year month
    getMonthDropdown(key?): Promise<any> {
        const p = key ? key : -1;
        return new Promise((resolve, reject) => {
            this.monthDd = [];
            this._httpService.Get(commonDdUrls.months + p).subscribe(response => {
                this.monthDd = response;
                resolve(this.monthsDd);
            }, err => {
                reject(new Error());
            });
        });
    }

    // get normal month
    getMonthsDropdown(): Promise<any> {

        return new Promise((resolve, reject) => {

            if (this.monthsDd.length) {
                resolve(this.monthsDd);
            } else {
                const monthRequest = this._httpService.Get(commonDdUrls.monthList);
                const yearRequest = this._httpService.Get(commonDdUrls.yearList);

                forkJoin([monthRequest, yearRequest])
                    .toPromise().then(response => {
                        const months = response[0];
                        const years = response[1];

                        this.monthOnlyDd = months.map(mapper => ({
                            value: mapper.monthName, key: Number(mapper.monthId)
                        }));
                        this.yearOnlyDd = years.map(mapper => ({
                            value: String(mapper.year), key: Number(mapper.year)
                        }));

                        for (let ele1 of years) {
                            for (let ele2 of months) {
                                const monthNumber = ele2.monthId - 1;
                                let date: any = new Date(ele1.year, monthNumber, 1, 0, 0, 0, 0);
                                const mom = moment(date).startOf("month");
                                date = mom.format('YYYY-MM-DD') + 'T00:00:00';
                                this.monthsDd.push({
                                    year: ele1.year,
                                    monthId: ele2.monthId,
                                    monthName: ele2.monthName,
                                    date: date,
                                    moment: mom,
                                    label: ele2.monthName + ', ' + ele1.year
                                });
                            }
                        }

                        this.monthsDd.reverse();
                        resolve(this.monthsDd);
                    }).catch(err => {
                        resolve(this.monthsDd);
                    });
            }
        });
    }

    getReligionDropDown() {
        this.religionDd = [
            { key: 'hindu', value: 'Hindu' },
            { key: 'muslim', value: 'Muslim' }
        ];
    }

    getWeekDaysDropDown() {

        if (this.weekDaysDd.length) {
            return;
        }

        this._httpService.Get(commonDdUrls.weekDays).subscribe((response) => {
            this.weekDaysDd = _.sortBy(response.map(mapper => ({
                value: mapper.weekName, key: mapper.weekId,
            })));
        });
    }

    getFinancialYearDd(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.financialYearDd.length) {
                resolve(this.financialYearDd);
            } else {
                this._httpService.Get(commonDdUrls.financialYear).subscribe(response => {
                    if (response?.length) {
                        this.financialYearDd = response.map(mapper => ({
                            value: mapper.name, key: mapper.id, startDate: mapper.startDate, endDate: mapper.endDate,
                            currentFinancialYear: mapper.currentFinancialYear
                        }));
                        resolve(this.financialYearDd);
                    } else {
                        throw new Error('Error');
                    }
                }, err => {
                    reject(err);
                });
            }
        });
    }

    getCurrentFinancialYear() {
        return new Promise((resolve, reject) => {
            this.getFinancialYearDd().then(res => {
                if (this.currentFinancialYear) {
                    resolve(this.currentFinancialYear);
                } else {
                    if (res && res.length) {
                        this.currentFinancialYear = res.find(ele => ele.currentFinancialYear);
                    }

                    if (this.currentFinancialYear) {
                        resolve(this.currentFinancialYear);
                    } else {
                        throw new Error('Error');
                    }
                }
            }).catch(err => {
                reject(err);
            });
        });
    }

    getSpecificEmpOtherStatusDropDown(): Promise<any> {

        return new Promise((resolve, reject) => {

            if (!this.specificEmpOtherStatusDd.length) {
                this._httpService.Get(commonDdUrls.otherStatus).toPromise().then((response) => {
                    this.specificEmpOtherStatusDd = response.map(mapper => ({
                        value: mapper.text, key: mapper.id,
                    })).sort((a,b) => a.value.localeCompare(b.value))

                    resolve(this.specificEmpOtherStatusDd);
                });
            } else {
                resolve(this.specificEmpOtherStatusDd);
            }

        });
    }

    reset() {
        this.moduleDd = [];
        this.currencyDd = [];
        this.genderDd = [];
        this.skillsDd = [];
        this.bloodGroupsDd = [];
        this.nationalityDd = [];
        this.employeeTypeDd = [];
        this.employeeStatusDd = [];
        this.specificEmpStatusDd = [];
        this.specificEmpOtherStatusDd = [];
        this.salutationDd = [];
        this.maritalStatusDd = [];
        this.parentsMediclaimDd = [];
        this.qualificationDd = [];
        this.qualificationTypeDd = [];
        this.specializationDd = [];
        this.nominationTypeDd = [];
        this.relationshipTypeDd = [];
        this.documentTypeDd = [];
        this.industryTypeDd = [];
        this.monthDd = [];
        this.monthsDd = [];  // genral
        this.monthOnlyDd = [];
        this.yearOnlyDd = [];
        this.payModeDd = [];
        this.bankDd = [];
        this.branchBankWiseDd = [];
        this.religionDd = [];
        this.weekDaysDd = [];
        this.financialYearDd = [];
        this.currentFinancialYear = null;
    }

}
